<template>
    <div class="ehd-page">
        <template v-if="isLoggedIn === true">
            <template v-if="Object.keys(EHDDetails).length > 0">
                <div class="ehd-actions-mobile">
                    <router-link
                        :to="{
                            name: 'FinaliseAndPlaceOrderPage',
                            query: { ehd_id: EHDDetails.id },
                        }"
                    >
                        <Button name="Finalise" primary />
                    </router-link>
                </div>

                <div class="mobile-ehd-details">
                    <div class="details-orange">
                        <p class="cost-details">
                            Total Cost:
                            <span
                                v-if="$route.query.ok"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹
                                {{ convertToIndianNumberSystem(kitchenPrice) }}
                            </span>
                            <span
                                v-else
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹
                                {{
                                    convertToIndianNumberSystem(
                                        EHDDetails.total_cost,
                                    )
                                }}
                            </span>
                        </p>
                        <p class="estimation-details">
                            Estimated Completion 4 weeks
                        </p>
                    </div>
                    <img
                        :src="
                            EHDDetails.designer_image
                                ? EHDDetails.designer_image
                                : require('assets/images/default-designer.png')
                        "
                        :alt="EHDDetails.designer"
                        class="designer-image"
                    />
                    <p class="detail-text">
                        Designed by {{ EHDDetails.designer }}
                    </p>
                    <div class="project-layout">
                        <p class="detail-text">
                            Project Name: {{ EHDDetails.project_name }}
                        </p>
                        <p class="detail-text">
                            Layout Type: {{ EHDDetails.floor_plan
                            }}<span v-if="EHDDetails.carpet_area"
                                >({{ EHDDetails.carpet_area }})</span
                            >
                        </p>
                    </div>
                </div>
                <div class="ehd-container">
                    <div class="ehd-content">
                        <div class="scene-container">
                            <div class="scene-body">
                                <div class="scene-img-wrapper">
                                    <img
                                        :src="
                                            default_scene_image !== ''
                                                ? default_scene_image
                                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                        "
                                    />

                                    <div class="ehd-scene-button">
                                        <a
                                            :href="
                                                EHDDetails.scene_data[
                                                    currentState
                                                ].vt_link
                                            "
                                            target="_blank"
                                            v-if="
                                                EHDDetails.scene_data[
                                                    currentState
                                                ].vt_link
                                            "
                                        >
                                            <div class="virtual-button">
                                                <span class="button-text"
                                                    >Virtual Tour</span
                                                >
                                                <VirtualButtonSVG />
                                            </div>
                                        </a>
                                        <template
                                            v-if="
                                                scene_thumbnails[currentState]
                                                    .length > 1
                                            "
                                        >
                                            <div
                                                class="image-button"
                                                @click.stop="
                                                    toggleSceneThumbnails
                                                "
                                                v-if="!showThumbnails"
                                                tabindex="0"
                                                @focus="hideThumbnails(false)"
                                                @blur="hideThumbnails(true)"
                                            >
                                                <span class="button-text"
                                                    >More Image</span
                                                >
                                                <EhdMoreImageButtonSVG />
                                            </div>
                                            <div
                                                class="nocursor-view"
                                                v-else
                                                tabindex="0"
                                                @focus="hideThumbnails(false)"
                                                @blur="hideThumbnails(true)"
                                            >
                                                <span class="more-text"
                                                    >More Image</span
                                                >
                                                <EhdMoreImageButtonSVG />
                                                <div class="dropdown_menu">
                                                    <div
                                                        class="scrollable-menu"
                                                    >
                                                        <div
                                                            v-for="(image,
                                                            i) in scene_thumbnails[
                                                                currentState
                                                            ]"
                                                            :key="i"
                                                            class="thumbnail-container"
                                                            @click="
                                                                changeDefaultSceneImage(
                                                                    image.image,
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="thumbnail-image"
                                                            >
                                                                <img
                                                                    :src="
                                                                        image.image
                                                                    "
                                                                    :class="
                                                                        default_scene_image ===
                                                                        image.image
                                                                            ? 'activeThumbnail'
                                                                            : ''
                                                                    "
                                                                />
                                                            </div>
                                                            <p>
                                                                {{
                                                                    image.scene_image_type
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <CaretSVG
                                                        direction="reverse"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <button
                                        class="ehd-full-screen"
                                        :href="
                                            default_scene_image !== ''
                                                ? default_scene_image
                                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                        "
                                        target="_blank"
                                        @click="showFullEhdModal(true)"
                                    >
                                        <EhdFullScreenSVG />
                                    </button>
                                </div>
                                <div class="mobile-img-wrapper">
                                    <template
                                        v-if="
                                            scene_thumbnails[currentState]
                                                .length > 0
                                        "
                                    >
                                        <!-- <Carousel :settings="settings">
                                            <div>
                                                <img
                                                    v-for="(image,
                                                    i) in scene_thumbnails[
                                                        currentState
                                                    ]"
                                                    :key="i"
                                                    :src="image.image"
                                                />
                                            </div>
                                        </Carousel> -->
                                        <img
                                            :src="
                                                scene_thumbnails[
                                                    currentState
                                                ][0].image
                                            "
                                        />
                                    </template>
                                    <template v-else>
                                        <img
                                            src="https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg"
                                        />
                                    </template>
                                    <a
                                        :href="
                                            EHDDetails.scene_data[currentState]
                                                .vt_link
                                        "
                                        target="_blank"
                                        v-if="
                                            EHDDetails.scene_data[currentState]
                                                .vt_link
                                        "
                                    >
                                        <div class="virtual-tour-button">
                                            <svg
                                                class="virtual-tour-tag"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="35.811"
                                                height="21.7"
                                                viewBox="0 0 35.811 21.7"
                                            >
                                                <g
                                                    id="Group_817"
                                                    data-name="Group 817"
                                                    transform="translate(-308.27 -739.192)"
                                                >
                                                    <g
                                                        id="Group_816"
                                                        data-name="Group 816"
                                                        transform="translate(315.881 738.193)"
                                                    >
                                                        <text
                                                            id="_360"
                                                            data-name="360"
                                                            class="cls-1"
                                                            transform="translate(0 14.772)"
                                                        >
                                                            <tspan x="0" y="0">
                                                                360
                                                            </tspan>
                                                        </text>
                                                        <g
                                                            id="Ellipse_231"
                                                            data-name="Ellipse 231"
                                                            class="cls-2"
                                                            transform="translate(20.259 1)"
                                                        >
                                                            <ellipse
                                                                class="cls-4"
                                                                cx="2.51"
                                                                cy="2.51"
                                                                rx="2.51"
                                                                ry="2.51"
                                                            />
                                                            <ellipse
                                                                class="cls-5"
                                                                cx="2.51"
                                                                cy="2.51"
                                                                rx="2.01"
                                                                ry="2.01"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="camera-degree-view-panorama-photo-icon-isolated-white-background-front-swap-eps-vector-186875817"
                                                        transform="translate(308.27 749.914)"
                                                    >
                                                        <path
                                                            id="Path_1009"
                                                            data-name="Path 1009"
                                                            class="cls-3"
                                                            d="M439.145-383.155a2.6,2.6,0,0,0,.876.567,6.806,6.806,0,0,1,1.443,1c.537.527.567.587.567,1.025s-.04.517-.438.9c-1.483,1.443-5.244,2.378-11.2,2.806-1.164.08-2.129.159-2.149.179a3.653,3.653,0,0,0,.756.418,4.75,4.75,0,0,1,.8.448,4.621,4.621,0,0,1-.9.557,6.723,6.723,0,0,0-.9.537,30.081,30.081,0,0,0,5.174-.557c6.379-1.174,10.13-3.184,9.931-5.314-.08-.846-.746-1.473-2.2-2.07C439.832-383.115,439.145-383.3,439.145-383.155Z"
                                                            transform="translate(-407.302 383.205)"
                                                        />
                                                        <path
                                                            id="Path_1010"
                                                            data-name="Path 1010"
                                                            class="cls-3"
                                                            d="M222.783-382.725c-2.5.935-3.244,1.93-2.528,3.383,1.115,2.219,7.463,4.348,14.12,4.737.547.03,1.174.08,1.4.109l.408.05-.239,1.154a11.149,11.149,0,0,0-.209,1.234,30.547,30.547,0,0,0,2.935-1.731,26.753,26.753,0,0,0,2.826-1.891c-.119-.119-5.811-3.264-5.841-3.234a8.666,8.666,0,0,0,.219,1.025c.358,1.5.478,1.373-1.174,1.294-5.7-.259-9.682-.955-11.971-2.1-2.358-1.174-2.179-2.408.537-3.771C224.425-383.044,224.106-383.223,222.783-382.725Z"
                                                            transform="translate(-219.996 383.034)"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Tabs
                            :key="EHDDetails.id"
                            :isselected="currentState"
                            @selectedTab="updateCurrentState"
                            :ehd="windowWidth > 1100 ? 'desktop' : 'mobile'"
                            class="scene-tabs"
                            :carousel="true"
                        >
                            <WarningBanner
                                v-if="showRemovedOverlay"
                                :productCategoryName="productCategoryName"
                                @restoreProduct="restoreProduct"
                            />
                            <Tab
                                v-for="(scene, index) in EHDDetails.scene_data"
                                :key="`${scene.id}_${index}`"
                                :title="scene.room_type"
                                :img="
                                    scene.scene_image_data.length > 0
                                        ? scene.scene_image_data[0]
                                              .scene_image_url
                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                "
                                :blur="!roomsAvailable[index].room_available"
                            >
                                <div class="scene-details">
                                    <div class="mobile-scene-details">
                                        <p class="detail-text">
                                            {{ scene.room_type }}
                                            |
                                            <span
                                                :class="
                                                    requiredDomain == false
                                                        ? 'blur'
                                                        : ''
                                                "
                                                >₹{{
                                                    convertToIndianNumberSystem(
                                                        scene.price,
                                                    )
                                                }}</span
                                            >
                                            |
                                            {{ EHDDetails.theme }}
                                        </p>
                                        <div class="total-price">
                                            <p>
                                                Included Products({{
                                                    roomData[currentState]
                                                        .activeProductCount
                                                }})<span
                                                    :class="
                                                        requiredDomain == false
                                                            ? 'blur'
                                                            : ''
                                                    "
                                                    >₹{{
                                                        convertToIndianNumberSystem(
                                                            roomData[
                                                                currentState
                                                            ].productCost,
                                                        )
                                                    }}</span
                                                >
                                            </p>
                                            <p>
                                                Included Services ({{
                                                    roomData[currentState]
                                                        .activeServiceCount
                                                }})<span
                                                    :class="
                                                        requiredDomain == false
                                                            ? 'blur'
                                                            : ''
                                                    "
                                                    >₹{{
                                                        convertToIndianNumberSystem(
                                                            roomData[
                                                                currentState
                                                            ].serviceCost,
                                                        )
                                                    }}</span
                                                >
                                            </p>
                                            <p class="total-cost">
                                                Total Cost<span
                                                    :class="
                                                        requiredDomain == false
                                                            ? 'blur'
                                                            : ''
                                                    "
                                                    >₹{{
                                                        convertToIndianNumberSystem(
                                                            roomData[
                                                                currentState
                                                            ].serviceCost +
                                                                roomData[
                                                                    currentState
                                                                ].productCost,
                                                        )
                                                    }}</span
                                                >
                                            </p>
                                        </div>

                                        <Button
                                            v-if="scene.scene_type == 'WHITE'"
                                            name="Customisation"
                                            orange
                                            class="customisation-button"
                                            :data-scene-id="scene.id"
                                            @click.native="
                                                checkDraft({
                                                    ehd_id: EHDDetails.id,
                                                    scene_id: scene.id,
                                                    operation: 'customise',
                                                    scene: scene.room_type,
                                                    scene_img:
                                                        scene
                                                            .scene_image_data[0]
                                                            .length != 0
                                                            ? scene
                                                                  .scene_image_data[0]
                                                                  .scene_image_url
                                                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',
                                                })
                                            "
                                        />
                                    </div>
                                    <div class="search-and-view">
                                        <SearchAutoComplete
                                            @search="
                                                fetchSearchedProductsOrServices
                                            "
                                            @clear="clearSearchedProducts()"
                                            class="search-complete"
                                        >
                                        </SearchAutoComplete>
                                        <div
                                            :class="
                                                currentTabTitle !=
                                                    'Included Services' &&
                                                currentTabTitle !=
                                                    'Custom Products' &&
                                                currentTabTitle != 'Services' &&
                                                currentTab != -1
                                                    ? 'viewby-category'
                                                    : 'viewby-single'
                                            "
                                        >
                                            <div
                                                class="category-list"
                                                @click="
                                                    displayViewByToggle(false)
                                                "
                                            >
                                                <CategoryListViewSVG
                                                    :fill="
                                                        displayView
                                                            ? '#bbbbbb'
                                                            : '#ff6100'
                                                    "
                                                />
                                            </div>
                                            <div
                                                v-if="
                                                    currentTabTitle !=
                                                        'Included Services' &&
                                                        currentTabTitle !=
                                                            'Services' &&
                                                        currentTab != -1 &&
                                                        currentTabTitle !=
                                                            'Custom Products'
                                                "
                                                class="category-thumb"
                                                @click="
                                                    displayViewByToggle(true)
                                                "
                                            >
                                                <CategoryListViewSVG
                                                    view="thumb"
                                                    :customClass="
                                                        displayView
                                                            ? 'cls-1'
                                                            : ''
                                                    "
                                                    :fill="
                                                        displayView
                                                            ? '#ff6100'
                                                            : '#bbbbbb'
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs
                                        :isselected="currentTab"
                                        @selectedTab="updateCurrentTab"
                                        @seletedTabTitle="updateCurrentTabTitle"
                                        :verticalTabs="
                                            windowWidth > 1360 ? true : false
                                        "
                                        :ehdHeader="
                                            windowWidth <= 1360 ? true : false
                                        "
                                    >
                                        <div
                                            v-if="
                                                queryString !== '' &&
                                                    searchedProducts.responseCode ===
                                                        200
                                            "
                                            class="products-active"
                                            style="width:100%;"
                                        >
                                            <template v-if="addProducts">
                                                <div class="add-more-container">
                                                    <p
                                                        class="add-product-header"
                                                    >
                                                        No Product Found
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <List
                                                    type="Products"
                                                    :obj="{ view: false }"
                                                    :List="
                                                        searchedProducts.payload
                                                            .base_products
                                                            .length > 0
                                                            ? searchedProducts
                                                                  .payload
                                                                  .base_products
                                                            : searchedProducts
                                                                  .payload
                                                                  .products
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                                <div
                                                    v-for="(product,
                                                    indx) in searchedProducts
                                                        .payload.base_products
                                                        .length > 0
                                                        ? searchedProducts
                                                              .payload
                                                              .base_products
                                                        : searchedProducts
                                                              .payload.products"
                                                    :key="indx"
                                                    class="single-product-mobile"
                                                >
                                                    <div
                                                        class="mobile-product-header"
                                                    >
                                                        <div
                                                            class="best-seller-tag-mobile"
                                                            v-if="
                                                                product.bestseller
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="27"
                                                                viewBox="0 0 30 27"
                                                            >
                                                                <g
                                                                    data-name="Group 2130"
                                                                    transform="translate(-246 -931)"
                                                                >
                                                                    <rect
                                                                        data-name="Rectangle 2258"
                                                                        width="30"
                                                                        height="27"
                                                                        rx="2"
                                                                        transform="translate(246 931)"
                                                                        style="fill:#f2fff3;opacity:.91"
                                                                    />
                                                                    <g
                                                                        data-name="Group 2023"
                                                                    >
                                                                        <path
                                                                            data-name="Polygon 46"
                                                                            d="M11.234.343a1 1 0 0 1 1.274.527l.917 2.065a1 1 0 0 0 .553.527l2.185.845a1 1 0 0 1 .553 1.338l-.865 1.948a1 1 0 0 0 0 .811l.865 1.948a1 1 0 0 1-.553 1.338l-2.185.845a1 1 0 0 0-.553.527l-.917 2.065a1 1 0 0 1-1.275.527l-2.3-.89a1 1 0 0 0-.721 0l-2.3.89a1 1 0 0 1-1.275-.527l-.917-2.065a1 1 0 0 0-.553-.527l-2.188-.843a1 1 0 0 1-.553-1.338l.865-1.948a1 1 0 0 0 0-.811L.426 5.646a1 1 0 0 1 .553-1.338l2.185-.845a1 1 0 0 0 .553-.527L4.635.87A1 1 0 0 1 5.909.343l2.3.89a1 1 0 0 0 .721 0z"
                                                                            transform="translate(252.428 937)"
                                                                            style="fill:#1ba925"
                                                                        />
                                                                        <g
                                                                            data-name="Group 1981"
                                                                        >
                                                                            <path
                                                                                data-name="Polygon 45"
                                                                                d="m4 0 1.2 2.686 2.8.37-2.058 2.03L6.472 8 4 6.569 1.528 8l.53-2.914L0 3.056l2.8-.37z"
                                                                                transform="translate(257 941)"
                                                                                style="fill:#fff"
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            Best seller
                                                        </div>
                                                        <div
                                                            :class="
                                                                product
                                                                    ? 'recommended-tag-mobile'
                                                                    : 'recommended-only-mobile'
                                                            "
                                                            v-if="
                                                                product.recommended
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18.412"
                                                                height="13.267"
                                                                viewBox="0 0 18.412 13.267"
                                                                class="recommended"
                                                            >
                                                                <g
                                                                    id="noun-recommended-1397309"
                                                                    transform="translate(-154.397 -140.221)"
                                                                >
                                                                    <path
                                                                        id="Path_2714"
                                                                        data-name="Path 2714"
                                                                        class="cls-1"
                                                                        d="M252.333,147.993a1.176,1.176,0,0,0-.493-.966,1.178,1.178,0,0,0,.493-.967,1.133,1.133,0,0,0-1.1-1.16H248.79a18,18,0,0,0,.647-2.652,1.91,1.91,0,0,0-.238-1.523,1,1,0,0,0-.79-.3c-.463,0-.6.552-.786,1.316-.089.362-.19.773-.34,1.215a9.785,9.785,0,0,1-.988,1.962l-.039.006a1.223,1.223,0,0,0-.983,1.037h-2.085v-.807a.194.194,0,0,0-.194-.194h-4.629a.194.194,0,1,0,0,.387H242.8V152.9h-4.435a.194.194,0,1,0,0,.387H243a.194.194,0,0,0,.194-.194v-.607h2.264a.16.16,0,0,0,.016,0,1.222,1.222,0,0,0,1.012.539h4.75a1.133,1.133,0,0,0,1.1-1.161,1.177,1.177,0,0,0-.493-.966,1.2,1.2,0,0,0,0-1.934,1.182,1.182,0,0,0,.493-.969Zm-7.074,4.107h-2.07v-5.756h2.07Zm5.973-3.333a.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547h-4.75a.836.836,0,0,1-.835-.835v-5.679a.836.836,0,0,1,.682-.82.674.674,0,0,1,.087-.007.2.2,0,0,0,.153-.088,10.36,10.36,0,0,0,1.082-2.129c.156-.458.258-.878.349-1.248.123-.5.251-1.021.41-1.021a.611.611,0,0,1,.5.171,1.647,1.647,0,0,1,.146,1.217,18.746,18.746,0,0,1-.712,2.838.193.193,0,0,0,.184.253h2.705a.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547Z"
                                                                        transform="translate(-79.724)"
                                                                    />
                                                                    <path
                                                                        id="Path_2715"
                                                                        data-name="Path 2715"
                                                                        class="cls-1"
                                                                        d="M202.044,258.2h3.011a.194.194,0,0,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                                                                        transform="translate(-45.076 -111.98)"
                                                                    />
                                                                    <path
                                                                        id="Path_2716"
                                                                        data-name="Path 2716"
                                                                        class="cls-1"
                                                                        d="M212.784,291.41a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(-55.321 -144.03)"
                                                                    />
                                                                    <path
                                                                        id="Path_2717"
                                                                        data-name="Path 2717"
                                                                        class="cls-1"
                                                                        d="M202.044,325.4h3.011a.194.194,0,1,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                                                                        transform="translate(-45.076 -176.083)"
                                                                    />
                                                                    <path
                                                                        id="Path_2718"
                                                                        data-name="Path 2718"
                                                                        class="cls-1"
                                                                        d="M212.784,358.61a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(-55.321 -208.133)"
                                                                    />
                                                                    <path
                                                                        id="Path_2719"
                                                                        data-name="Path 2719"
                                                                        class="cls-1"
                                                                        d="M156.134,291.41H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(0 -144.03)"
                                                                    />
                                                                    <path
                                                                        id="Path_2720"
                                                                        data-name="Path 2720"
                                                                        class="cls-1"
                                                                        d="M156.134,358.61H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,1,0,0-.387Z"
                                                                        transform="translate(0 -208.133)"
                                                                    />
                                                                    <path
                                                                        id="Path_2721"
                                                                        data-name="Path 2721"
                                                                        class="cls-1"
                                                                        d="M205.254,392.4a.194.194,0,0,0-.194-.194h-3.011a.194.194,0,1,0,0,.387h3.011A.193.193,0,0,0,205.254,392.4Z"
                                                                        transform="translate(-45.081 -240.185)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            Recommended by
                                                            Siloho
                                                        </div>
                                                        <div
                                                            v-if="
                                                                product.active_quantity !==
                                                                    0
                                                            "
                                                            class="action-remove-mobile"
                                                            @click="
                                                                checkDraft({
                                                                    product: product,
                                                                    scene_id:
                                                                        scene.id,
                                                                    operation:
                                                                        'toggle',
                                                                    enable: false,
                                                                    ehd_id:
                                                                        EHDDetails.id,
                                                                })
                                                            "
                                                        >
                                                            <svg
                                                                id="noun_dustbin_3666639"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="9.934"
                                                                height="12.608"
                                                                viewBox="0 0 9.934 12.608"
                                                            >
                                                                <g
                                                                    id="Group_493"
                                                                    data-name="Group 493"
                                                                    transform="translate(0 0)"
                                                                >
                                                                    <path
                                                                        id="Path_844"
                                                                        data-name="Path 844"
                                                                        class="cls-1"
                                                                        d="M17.35,21l1.056,9.089a.955.955,0,0,0,.949.844H24a.955.955,0,0,0,.949-.844L26,21Zm2.8,8.191a.093.093,0,0,1-.023,0,.192.192,0,0,1-.189-.17l-.766-6.835a.191.191,0,0,1,.38-.042l.766,6.835A.191.191,0,0,1,20.149,29.191Zm1.719-.277a.191.191,0,1,1-.382,0V22.037a.191.191,0,0,1,.382,0Zm1.549.109a.192.192,0,0,1-.189.17.094.094,0,0,1-.023,0,.191.191,0,0,1-.168-.21l.766-6.835a.191.191,0,0,1,.38.042Z"
                                                                        transform="translate(-16.71 -18.326)"
                                                                    />
                                                                    <path
                                                                        id="Path_845"
                                                                        data-name="Path 845"
                                                                        class="cls-1"
                                                                        d="M22.6,7.764H20.667A.955.955,0,0,0,19.731,7H18.2a.958.958,0,0,0-.936.764H15.337A1.339,1.339,0,0,0,14,9.1a.192.192,0,0,0,.191.191h9.552a.192.192,0,0,0,.191-.191A1.339,1.339,0,0,0,22.6,7.764ZM18.2,7.382h1.528a.576.576,0,0,1,.541.382H17.662A.576.576,0,0,1,18.2,7.382Z"
                                                                        transform="translate(-14 -7)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mobile-product-body"
                                                    >
                                                        <div
                                                            class="mobile-product-details"
                                                        >
                                                            <p
                                                                class="prod-name"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        15,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            15
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="prod-vendor"
                                                            >
                                                                {{
                                                                    product.vendor
                                                                }}
                                                            </p>
                                                            <p
                                                                class="prod-price"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        product.item_price,
                                                                    )
                                                                }}
                                                            </p>
                                                            <div
                                                                class="mobile-actions"
                                                            >
                                                                <template
                                                                    v-if="
                                                                        product.active_quantity !==
                                                                            0
                                                                    "
                                                                >
                                                                    <div
                                                                        class="mobile-change"
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    quantity:
                                                                                        product.total_quantity,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    index: index,
                                                                                    operation:
                                                                                        'change',
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        CHANGE
                                                                    </div>
                                                                    <div
                                                                        class="number-of-products"
                                                                    >
                                                                        <div
                                                                            class="remove-one"
                                                                            v-if="
                                                                                product.active_quantity !=
                                                                                    0
                                                                            "
                                                                            @click="
                                                                                checkDraft(
                                                                                    {
                                                                                        product: product,
                                                                                        scene_id:
                                                                                            scene.id,
                                                                                        operation:
                                                                                            'count',
                                                                                        enable: false,
                                                                                        ehd_id:
                                                                                            EHDDetails.id,
                                                                                    },
                                                                                )
                                                                            "
                                                                        >
                                                                            <svg
                                                                                viewbox="0 0 10 10"
                                                                            >
                                                                                <line
                                                                                    x1="6"
                                                                                    y1="11.5"
                                                                                    x2="16"
                                                                                    y2="11.5"
                                                                                    fill="none"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    vector-effect="non-scaling-stroke"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                        <div
                                                                            v-else
                                                                            class="disable-icon"
                                                                        >
                                                                            <svg
                                                                                viewbox="0 0 10 10"
                                                                            >
                                                                                <line
                                                                                    x1="6"
                                                                                    y1="11.5"
                                                                                    x2="16"
                                                                                    y2="11.5"
                                                                                    fill="none"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    vector-effect="non-scaling-stroke"
                                                                                />
                                                                            </svg>
                                                                        </div>

                                                                        <div
                                                                            class="total"
                                                                        >
                                                                            {{
                                                                                product.active_quantity
                                                                            }}
                                                                        </div>

                                                                        <div
                                                                            class="add-one"
                                                                            @click="
                                                                                checkDraft(
                                                                                    {
                                                                                        product: product,
                                                                                        scene_id:
                                                                                            scene.id,
                                                                                        operation:
                                                                                            'count',
                                                                                        enable: true,
                                                                                        ehd_id:
                                                                                            EHDDetails.id,
                                                                                    },
                                                                                )
                                                                            "
                                                                        >
                                                                            <svg
                                                                                viewbox="0 0 10 10"
                                                                            >
                                                                                <line
                                                                                    x1="6"
                                                                                    y1="11.5"
                                                                                    x2="15"
                                                                                    y2="11.5"
                                                                                    fill="none"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    vector-effect="non-scaling-stroke"
                                                                                />
                                                                                <line
                                                                                    y1="7"
                                                                                    x1="10.5"
                                                                                    y2="16"
                                                                                    x2="10.5"
                                                                                    fill="none"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    vector-effect="non-scaling-stroke"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                                <template
                                                                    v-else
                                                                >
                                                                    <div
                                                                        class="mobile-add"
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    operation:
                                                                                        'restore',
                                                                                    enable: false,
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        + ADD TO
                                                                        ROOM
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="mobile-product-image"
                                                        >
                                                            <img
                                                                :src="
                                                                    product.image_url
                                                                        ? product.image_url
                                                                        : require('@/assets/images/default-product-img.png')
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            product.available ===
                                                                false
                                                        "
                                                        class="mobile-unavailble"
                                                    >
                                                        Product Unavailable
                                                    </div>
                                                </div>
                                            </template>
                                            <p
                                                @click="
                                                    checkDraft({
                                                        ehd_id: EHDDetails.id,
                                                        scene_id: scene.id,
                                                        operation: 'add',
                                                        scene_type:
                                                            scene.scene_type,
                                                    })
                                                "
                                                class="add-prod-button"
                                            >
                                                + Add New Product
                                            </p>
                                        </div>
                                        <Tab
                                            :title="`Included Products`"
                                            :verticalTab="true"
                                        >
                                            <div
                                                v-if="
                                                    roomData[currentState]
                                                        .products.length > 0 ||
                                                        roomData[currentState]
                                                            .customProducts
                                                            .length > 0
                                                "
                                                class="products-active"
                                            >
                                                <List
                                                    type="Products"
                                                    :obj="{
                                                        view: displayView,
                                                    }"
                                                    :List="
                                                        roomData[
                                                            currentState
                                                        ].products.concat(
                                                            roomData[
                                                                currentState
                                                            ].customProducts,
                                                        )
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                                <div
                                                    v-for="(product,
                                                    indx) in roomData[
                                                        currentState
                                                    ].products"
                                                    :key="indx"
                                                    class="single-product-mobile"
                                                >
                                                    <div
                                                        class="mobile-product-header"
                                                    >
                                                        <div
                                                            class="best-seller-tag-mobile"
                                                            v-if="
                                                                product.bestseller
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="27"
                                                                viewBox="0 0 30 27"
                                                            >
                                                                <g
                                                                    data-name="Group 2130"
                                                                    transform="translate(-246 -931)"
                                                                >
                                                                    <rect
                                                                        data-name="Rectangle 2258"
                                                                        width="30"
                                                                        height="27"
                                                                        rx="2"
                                                                        transform="translate(246 931)"
                                                                        style="fill:#f2fff3;opacity:.91"
                                                                    />
                                                                    <g
                                                                        data-name="Group 2023"
                                                                    >
                                                                        <path
                                                                            data-name="Polygon 46"
                                                                            d="M11.234.343a1 1 0 0 1 1.274.527l.917 2.065a1 1 0 0 0 .553.527l2.185.845a1 1 0 0 1 .553 1.338l-.865 1.948a1 1 0 0 0 0 .811l.865 1.948a1 1 0 0 1-.553 1.338l-2.185.845a1 1 0 0 0-.553.527l-.917 2.065a1 1 0 0 1-1.275.527l-2.3-.89a1 1 0 0 0-.721 0l-2.3.89a1 1 0 0 1-1.275-.527l-.917-2.065a1 1 0 0 0-.553-.527l-2.188-.843a1 1 0 0 1-.553-1.338l.865-1.948a1 1 0 0 0 0-.811L.426 5.646a1 1 0 0 1 .553-1.338l2.185-.845a1 1 0 0 0 .553-.527L4.635.87A1 1 0 0 1 5.909.343l2.3.89a1 1 0 0 0 .721 0z"
                                                                            transform="translate(252.428 937)"
                                                                            style="fill:#1ba925"
                                                                        />
                                                                        <g
                                                                            data-name="Group 1981"
                                                                        >
                                                                            <path
                                                                                data-name="Polygon 45"
                                                                                d="m4 0 1.2 2.686 2.8.37-2.058 2.03L6.472 8 4 6.569 1.528 8l.53-2.914L0 3.056l2.8-.37z"
                                                                                transform="translate(257 941)"
                                                                                style="fill:#fff"
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            Best seller
                                                        </div>
                                                        <div
                                                            :class="
                                                                product
                                                                    ? 'recommended-tag-mobile'
                                                                    : 'recommended-only-mobile'
                                                            "
                                                            v-if="
                                                                product.recommended
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18.412"
                                                                height="13.267"
                                                                viewBox="0 0 18.412 13.267"
                                                                class="recommended"
                                                            >
                                                                <g
                                                                    id="noun-recommended-1397309"
                                                                    transform="translate(-154.397 -140.221)"
                                                                >
                                                                    <path
                                                                        id="Path_2714"
                                                                        data-name="Path 2714"
                                                                        class="cls-1"
                                                                        d="M252.333,147.993a1.176,1.176,0,0,0-.493-.966,1.178,1.178,0,0,0,.493-.967,1.133,1.133,0,0,0-1.1-1.16H248.79a18,18,0,0,0,.647-2.652,1.91,1.91,0,0,0-.238-1.523,1,1,0,0,0-.79-.3c-.463,0-.6.552-.786,1.316-.089.362-.19.773-.34,1.215a9.785,9.785,0,0,1-.988,1.962l-.039.006a1.223,1.223,0,0,0-.983,1.037h-2.085v-.807a.194.194,0,0,0-.194-.194h-4.629a.194.194,0,1,0,0,.387H242.8V152.9h-4.435a.194.194,0,1,0,0,.387H243a.194.194,0,0,0,.194-.194v-.607h2.264a.16.16,0,0,0,.016,0,1.222,1.222,0,0,0,1.012.539h4.75a1.133,1.133,0,0,0,1.1-1.161,1.177,1.177,0,0,0-.493-.966,1.2,1.2,0,0,0,0-1.934,1.182,1.182,0,0,0,.493-.969Zm-7.074,4.107h-2.07v-5.756h2.07Zm5.973-3.333a.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547h-4.75a.836.836,0,0,1-.835-.835v-5.679a.836.836,0,0,1,.682-.82.674.674,0,0,1,.087-.007.2.2,0,0,0,.153-.088,10.36,10.36,0,0,0,1.082-2.129c.156-.458.258-.878.349-1.248.123-.5.251-1.021.41-1.021a.611.611,0,0,1,.5.171,1.647,1.647,0,0,1,.146,1.217,18.746,18.746,0,0,1-.712,2.838.193.193,0,0,0,.184.253h2.705a.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547Z"
                                                                        transform="translate(-79.724)"
                                                                    />
                                                                    <path
                                                                        id="Path_2715"
                                                                        data-name="Path 2715"
                                                                        class="cls-1"
                                                                        d="M202.044,258.2h3.011a.194.194,0,0,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                                                                        transform="translate(-45.076 -111.98)"
                                                                    />
                                                                    <path
                                                                        id="Path_2716"
                                                                        data-name="Path 2716"
                                                                        class="cls-1"
                                                                        d="M212.784,291.41a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(-55.321 -144.03)"
                                                                    />
                                                                    <path
                                                                        id="Path_2717"
                                                                        data-name="Path 2717"
                                                                        class="cls-1"
                                                                        d="M202.044,325.4h3.011a.194.194,0,1,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                                                                        transform="translate(-45.076 -176.083)"
                                                                    />
                                                                    <path
                                                                        id="Path_2718"
                                                                        data-name="Path 2718"
                                                                        class="cls-1"
                                                                        d="M212.784,358.61a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(-55.321 -208.133)"
                                                                    />
                                                                    <path
                                                                        id="Path_2719"
                                                                        data-name="Path 2719"
                                                                        class="cls-1"
                                                                        d="M156.134,291.41H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(0 -144.03)"
                                                                    />
                                                                    <path
                                                                        id="Path_2720"
                                                                        data-name="Path 2720"
                                                                        class="cls-1"
                                                                        d="M156.134,358.61H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,1,0,0-.387Z"
                                                                        transform="translate(0 -208.133)"
                                                                    />
                                                                    <path
                                                                        id="Path_2721"
                                                                        data-name="Path 2721"
                                                                        class="cls-1"
                                                                        d="M205.254,392.4a.194.194,0,0,0-.194-.194h-3.011a.194.194,0,1,0,0,.387h3.011A.193.193,0,0,0,205.254,392.4Z"
                                                                        transform="translate(-45.081 -240.185)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            Recommended by
                                                            Siloho
                                                        </div>
                                                        <div
                                                            class="action-remove-mobile"
                                                            @click="
                                                                checkDraft({
                                                                    product: product,
                                                                    scene_id:
                                                                        scene.id,
                                                                    operation:
                                                                        'toggle',
                                                                    enable: false,
                                                                    ehd_id:
                                                                        EHDDetails.id,
                                                                })
                                                            "
                                                        >
                                                            <svg
                                                                id="noun_dustbin_3666639"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="9.934"
                                                                height="12.608"
                                                                viewBox="0 0 9.934 12.608"
                                                            >
                                                                <g
                                                                    id="Group_493"
                                                                    data-name="Group 493"
                                                                    transform="translate(0 0)"
                                                                >
                                                                    <path
                                                                        id="Path_844"
                                                                        data-name="Path 844"
                                                                        class="cls-1"
                                                                        d="M17.35,21l1.056,9.089a.955.955,0,0,0,.949.844H24a.955.955,0,0,0,.949-.844L26,21Zm2.8,8.191a.093.093,0,0,1-.023,0,.192.192,0,0,1-.189-.17l-.766-6.835a.191.191,0,0,1,.38-.042l.766,6.835A.191.191,0,0,1,20.149,29.191Zm1.719-.277a.191.191,0,1,1-.382,0V22.037a.191.191,0,0,1,.382,0Zm1.549.109a.192.192,0,0,1-.189.17.094.094,0,0,1-.023,0,.191.191,0,0,1-.168-.21l.766-6.835a.191.191,0,0,1,.38.042Z"
                                                                        transform="translate(-16.71 -18.326)"
                                                                    />
                                                                    <path
                                                                        id="Path_845"
                                                                        data-name="Path 845"
                                                                        class="cls-1"
                                                                        d="M22.6,7.764H20.667A.955.955,0,0,0,19.731,7H18.2a.958.958,0,0,0-.936.764H15.337A1.339,1.339,0,0,0,14,9.1a.192.192,0,0,0,.191.191h9.552a.192.192,0,0,0,.191-.191A1.339,1.339,0,0,0,22.6,7.764ZM18.2,7.382h1.528a.576.576,0,0,1,.541.382H17.662A.576.576,0,0,1,18.2,7.382Z"
                                                                        transform="translate(-14 -7)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mobile-product-body"
                                                    >
                                                        <div
                                                            class="mobile-product-details"
                                                        >
                                                            <p
                                                                class="prod-name"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        15,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            15
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="prod-vendor"
                                                            >
                                                                {{
                                                                    product.vendor
                                                                }}
                                                            </p>
                                                            <p
                                                                class="prod-price"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        product.item_price,
                                                                    )
                                                                }}
                                                            </p>
                                                            <div
                                                                class="mobile-actions"
                                                            >
                                                                <div
                                                                    class="mobile-change"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                product: product,
                                                                                quantity:
                                                                                    product.total_quantity,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                index: index,
                                                                                operation:
                                                                                    'change',
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    CHANGE
                                                                </div>
                                                                <div
                                                                    class="number-of-products"
                                                                >
                                                                    <div
                                                                        class="remove-one"
                                                                        v-if="
                                                                            product.active_quantity !=
                                                                                0
                                                                        "
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    operation:
                                                                                        'count',
                                                                                    enable: false,
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        <svg
                                                                            viewbox="0 0 10 10"
                                                                        >
                                                                            <line
                                                                                x1="6"
                                                                                y1="11.5"
                                                                                x2="16"
                                                                                y2="11.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                    <div
                                                                        v-else
                                                                        class="disable-icon"
                                                                    >
                                                                        <svg
                                                                            viewbox="0 0 10 10"
                                                                        >
                                                                            <line
                                                                                x1="6"
                                                                                y1="11.5"
                                                                                x2="16"
                                                                                y2="11.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                        </svg>
                                                                    </div>

                                                                    <div
                                                                        class="total"
                                                                    >
                                                                        {{
                                                                            product.active_quantity
                                                                        }}
                                                                    </div>

                                                                    <div
                                                                        class="add-one"
                                                                        @click="
                                                                            checkDraft(
                                                                                {
                                                                                    product: product,
                                                                                    scene_id:
                                                                                        scene.id,
                                                                                    operation:
                                                                                        'count',
                                                                                    enable: true,
                                                                                    ehd_id:
                                                                                        EHDDetails.id,
                                                                                },
                                                                            )
                                                                        "
                                                                    >
                                                                        <svg
                                                                            viewbox="0 0 10 10"
                                                                        >
                                                                            <line
                                                                                x1="6"
                                                                                y1="11.5"
                                                                                x2="15"
                                                                                y2="11.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                            <line
                                                                                y1="7"
                                                                                x1="10.5"
                                                                                y2="16"
                                                                                x2="10.5"
                                                                                fill="none"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                vector-effect="non-scaling-stroke"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="mobile-product-image"
                                                        >
                                                            <img
                                                                :src="
                                                                    product.image_url
                                                                        ? product.image_url
                                                                        : require('@/assets/images/default-product-img.png')
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            product.available ===
                                                                false
                                                        "
                                                        class="mobile-unavailble"
                                                    >
                                                        Product Unavailable
                                                    </div>
                                                </div>
                                                <div
                                                    v-for="(product,
                                                    indx) in roomData[
                                                        currentState
                                                    ].customProducts"
                                                    :key="indx"
                                                    class="single-product-mobile"
                                                >
                                                    <div
                                                        class="mobile-product-header"
                                                    >
                                                        <div
                                                            class="action-remove-mobile"
                                                            @click="
                                                                checkDraft({
                                                                    product: product,
                                                                    scene_id:
                                                                        scene.id,
                                                                    operation:
                                                                        'customProduct',
                                                                    enable: false,
                                                                    ehd_id:
                                                                        EHDDetails.id,
                                                                })
                                                            "
                                                        >
                                                            <svg
                                                                id="noun_dustbin_3666639"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="9.934"
                                                                height="12.608"
                                                                viewBox="0 0 9.934 12.608"
                                                            >
                                                                <g
                                                                    id="Group_493"
                                                                    data-name="Group 493"
                                                                    transform="translate(0 0)"
                                                                >
                                                                    <path
                                                                        id="Path_844"
                                                                        data-name="Path 844"
                                                                        class="cls-1"
                                                                        d="M17.35,21l1.056,9.089a.955.955,0,0,0,.949.844H24a.955.955,0,0,0,.949-.844L26,21Zm2.8,8.191a.093.093,0,0,1-.023,0,.192.192,0,0,1-.189-.17l-.766-6.835a.191.191,0,0,1,.38-.042l.766,6.835A.191.191,0,0,1,20.149,29.191Zm1.719-.277a.191.191,0,1,1-.382,0V22.037a.191.191,0,0,1,.382,0Zm1.549.109a.192.192,0,0,1-.189.17.094.094,0,0,1-.023,0,.191.191,0,0,1-.168-.21l.766-6.835a.191.191,0,0,1,.38.042Z"
                                                                        transform="translate(-16.71 -18.326)"
                                                                    />
                                                                    <path
                                                                        id="Path_845"
                                                                        data-name="Path 845"
                                                                        class="cls-1"
                                                                        d="M22.6,7.764H20.667A.955.955,0,0,0,19.731,7H18.2a.958.958,0,0,0-.936.764H15.337A1.339,1.339,0,0,0,14,9.1a.192.192,0,0,0,.191.191h9.552a.192.192,0,0,0,.191-.191A1.339,1.339,0,0,0,22.6,7.764ZM18.2,7.382h1.528a.576.576,0,0,1,.541.382H17.662A.576.576,0,0,1,18.2,7.382Z"
                                                                        transform="translate(-14 -7)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mobile-product-body"
                                                    >
                                                        <div
                                                            class="mobile-product-details"
                                                        >
                                                            <p
                                                                class="prod-name"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        15,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            15
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="prod-vendor"
                                                            >
                                                                {{
                                                                    product.vendor
                                                                }}
                                                            </p>
                                                            <p
                                                                class="prod-price"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        product.price,
                                                                    )
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="mobile-custom-image"
                                                        >
                                                            <img
                                                                :src="
                                                                    product.image_url
                                                                        ? product.image_url
                                                                        : require('@/assets/images/default-product-img.png')
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                v-else
                                                class="no_active_product"
                                            >
                                                <p
                                                    class="no_active_product_content"
                                                >
                                                    No active products
                                                </p>
                                            </div>
                                        </Tab>
                                        <Tab
                                            :title="`Included Services`"
                                            :verticalTab="true"
                                        >
                                            <div
                                                v-if="
                                                    roomData[currentState]
                                                        .services.length > 0
                                                "
                                                class="services-active"
                                            >
                                                <List
                                                    type="Services"
                                                    :obj="{
                                                        active: true,
                                                    }"
                                                    :List="
                                                        roomData[currentState]
                                                            .services
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                                <div
                                                    v-for="(service,
                                                    index) in roomData[
                                                        currentState
                                                    ].services"
                                                    :key="index"
                                                    class="single-service-mobile"
                                                >
                                                    <div
                                                        class="service-category"
                                                    >
                                                        <img
                                                            :src="
                                                                service.icon_url
                                                                    ? service.icon_url
                                                                    : require('@/assets/images/enable_service.png')
                                                            "
                                                            class="service-image"
                                                        />
                                                        <p class="service-type">
                                                            {{ service.type }}
                                                        </p>
                                                        <div class="actions">
                                                            <div
                                                                class="action-remove"
                                                                @click="
                                                                    checkDraft({
                                                                        operation:
                                                                            'allService',
                                                                        type:
                                                                            service.type,
                                                                        scene_id:
                                                                            scene.id,
                                                                        enable: false,
                                                                        ehd_id:
                                                                            EHDDetails.id,
                                                                    })
                                                                "
                                                            >
                                                                <svg
                                                                    id="noun_dustbin_3666639"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="9.934"
                                                                    height="12.608"
                                                                    viewBox="0 0 9.934 12.608"
                                                                >
                                                                    <g
                                                                        id="Group_493"
                                                                        data-name="Group 493"
                                                                        transform="translate(0 0)"
                                                                    >
                                                                        <path
                                                                            id="Path_844"
                                                                            data-name="Path 844"
                                                                            class="cls-1"
                                                                            d="M17.35,21l1.056,9.089a.955.955,0,0,0,.949.844H24a.955.955,0,0,0,.949-.844L26,21Zm2.8,8.191a.093.093,0,0,1-.023,0,.192.192,0,0,1-.189-.17l-.766-6.835a.191.191,0,0,1,.38-.042l.766,6.835A.191.191,0,0,1,20.149,29.191Zm1.719-.277a.191.191,0,1,1-.382,0V22.037a.191.191,0,0,1,.382,0Zm1.549.109a.192.192,0,0,1-.189.17.094.094,0,0,1-.023,0,.191.191,0,0,1-.168-.21l.766-6.835a.191.191,0,0,1,.38.042Z"
                                                                            transform="translate(-16.71 -18.326)"
                                                                        />
                                                                        <path
                                                                            id="Path_845"
                                                                            data-name="Path 845"
                                                                            class="cls-1"
                                                                            d="M22.6,7.764H20.667A.955.955,0,0,0,19.731,7H18.2a.958.958,0,0,0-.936.764H15.337A1.339,1.339,0,0,0,14,9.1a.192.192,0,0,0,.191.191h9.552a.192.192,0,0,0,.191-.191A1.339,1.339,0,0,0,22.6,7.764ZM18.2,7.382h1.528a.576.576,0,0,1,.541.382H17.662A.576.576,0,0,1,18.2,7.382Z"
                                                                            transform="translate(-14 -7)"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="service-info "
                                                        :class="
                                                            idx === 0
                                                                ? ''
                                                                : 'service-hr'
                                                        "
                                                        v-for="(services,
                                                        idx) in service.data"
                                                        :key="services.id"
                                                    >
                                                        <div
                                                            class="service-details"
                                                        >
                                                            <p
                                                                class="service-name"
                                                            >
                                                                {{
                                                                    services.name
                                                                }}
                                                            </p>
                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="action-remove"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                operation:
                                                                                    'service',
                                                                                service_id:
                                                                                    services.id,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                enable: false,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    <svg
                                                                        id="noun_dustbin_3666639"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="9.934"
                                                                        height="12.608"
                                                                        viewBox="0 0 9.934 12.608"
                                                                    >
                                                                        <g
                                                                            id="Group_493"
                                                                            data-name="Group 493"
                                                                            transform="translate(0 0)"
                                                                        >
                                                                            <path
                                                                                id="Path_844"
                                                                                data-name="Path 844"
                                                                                class="cls-1"
                                                                                d="M17.35,21l1.056,9.089a.955.955,0,0,0,.949.844H24a.955.955,0,0,0,.949-.844L26,21Zm2.8,8.191a.093.093,0,0,1-.023,0,.192.192,0,0,1-.189-.17l-.766-6.835a.191.191,0,0,1,.38-.042l.766,6.835A.191.191,0,0,1,20.149,29.191Zm1.719-.277a.191.191,0,1,1-.382,0V22.037a.191.191,0,0,1,.382,0Zm1.549.109a.192.192,0,0,1-.189.17.094.094,0,0,1-.023,0,.191.191,0,0,1-.168-.21l.766-6.835a.191.191,0,0,1,.38.042Z"
                                                                                transform="translate(-16.71 -18.326)"
                                                                            />
                                                                            <path
                                                                                id="Path_845"
                                                                                data-name="Path 845"
                                                                                class="cls-1"
                                                                                d="M22.6,7.764H20.667A.955.955,0,0,0,19.731,7H18.2a.958.958,0,0,0-.936.764H15.337A1.339,1.339,0,0,0,14,9.1a.192.192,0,0,0,.191.191h9.552a.192.192,0,0,0,.191-.191A1.339,1.339,0,0,0,22.6,7.764ZM18.2,7.382h1.528a.576.576,0,0,1,.541.382H17.662A.576.576,0,0,1,18.2,7.382Z"
                                                                                transform="translate(-14 -7)"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="service-price"
                                                        >
                                                            <p
                                                                class="service-data"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        services.rate,
                                                                    )
                                                                }}
                                                                {{
                                                                    services.unit
                                                                }}
                                                            </p>
                                                            <p
                                                                class="service-data"
                                                            >
                                                                {{
                                                                    services.quantity
                                                                }}
                                                            </p>

                                                            <p
                                                                class="service-data"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        services.price,
                                                                    )
                                                                }}
                                                            </p>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                class="info-icon"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                @click="
                                                                    showServiceDescription(
                                                                        services.description,
                                                                    )
                                                                "
                                                            >
                                                                <g
                                                                    id="Group_2146"
                                                                    data-name="Group 2146"
                                                                    transform="translate(-930 -971)"
                                                                >
                                                                    <g
                                                                        id="Ellipse_608"
                                                                        data-name="Ellipse 608"
                                                                        class="cls-1"
                                                                        transform="translate(930 971)"
                                                                    >
                                                                        <circle
                                                                            class="cls-3"
                                                                            cx="8.5"
                                                                            cy="8.5"
                                                                            r="8.5"
                                                                        />
                                                                        <circle
                                                                            class="cls-4"
                                                                            cx="8.5"
                                                                            cy="8.5"
                                                                            r="7.85"
                                                                        />
                                                                    </g>
                                                                    <text
                                                                        id="i"
                                                                        class="cls-2"
                                                                        transform="translate(937 984)"
                                                                    >
                                                                        <tspan
                                                                            x="0"
                                                                            y="0"
                                                                        >
                                                                            i
                                                                        </tspan>
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="no_active_product"
                                            >
                                                <p
                                                    class="no_active_product_content"
                                                >
                                                    No active services
                                                    available.
                                                </p>
                                            </div>
                                        </Tab>

                                        <Tab
                                            v-for="(data, index) in roomData[
                                                currentState
                                            ].categoryGroups"
                                            :title="data"
                                            :verticalTab="true"
                                            :key="index"
                                        >
                                            <div class="products-active">
                                                <p
                                                    class="add-all-products"
                                                    @click="
                                                        checkDraft({
                                                            product:
                                                                removedProduct[
                                                                    data
                                                                ][0],
                                                            scene_id: scene.id,
                                                            operation: 'addAll',
                                                            enable: true,
                                                            ehd_id:
                                                                EHDDetails.id,
                                                            category: data,
                                                        })
                                                    "
                                                >
                                                    + Add all
                                                    {{ data }} Products
                                                </p>
                                                <List
                                                    type="Products"
                                                    :obj="{
                                                        view: displayView,
                                                    }"
                                                    :List="removedProduct[data]"
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                                <div
                                                    v-for="(product,
                                                    indx) in removedProduct[
                                                        data
                                                    ]"
                                                    :key="indx"
                                                    class="single-product-mobile"
                                                >
                                                    <div
                                                        class="mobile-product-header"
                                                    >
                                                        <div
                                                            class="best-seller-tag-mobile"
                                                            v-if="
                                                                product.bestseller
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="27"
                                                                viewBox="0 0 30 27"
                                                            >
                                                                <g
                                                                    data-name="Group 2130"
                                                                    transform="translate(-246 -931)"
                                                                >
                                                                    <rect
                                                                        data-name="Rectangle 2258"
                                                                        width="30"
                                                                        height="27"
                                                                        rx="2"
                                                                        transform="translate(246 931)"
                                                                        style="fill:#f2fff3;opacity:.91"
                                                                    />
                                                                    <g
                                                                        data-name="Group 2023"
                                                                    >
                                                                        <path
                                                                            data-name="Polygon 46"
                                                                            d="M11.234.343a1 1 0 0 1 1.274.527l.917 2.065a1 1 0 0 0 .553.527l2.185.845a1 1 0 0 1 .553 1.338l-.865 1.948a1 1 0 0 0 0 .811l.865 1.948a1 1 0 0 1-.553 1.338l-2.185.845a1 1 0 0 0-.553.527l-.917 2.065a1 1 0 0 1-1.275.527l-2.3-.89a1 1 0 0 0-.721 0l-2.3.89a1 1 0 0 1-1.275-.527l-.917-2.065a1 1 0 0 0-.553-.527l-2.188-.843a1 1 0 0 1-.553-1.338l.865-1.948a1 1 0 0 0 0-.811L.426 5.646a1 1 0 0 1 .553-1.338l2.185-.845a1 1 0 0 0 .553-.527L4.635.87A1 1 0 0 1 5.909.343l2.3.89a1 1 0 0 0 .721 0z"
                                                                            transform="translate(252.428 937)"
                                                                            style="fill:#1ba925"
                                                                        />
                                                                        <g
                                                                            data-name="Group 1981"
                                                                        >
                                                                            <path
                                                                                data-name="Polygon 45"
                                                                                d="m4 0 1.2 2.686 2.8.37-2.058 2.03L6.472 8 4 6.569 1.528 8l.53-2.914L0 3.056l2.8-.37z"
                                                                                transform="translate(257 941)"
                                                                                style="fill:#fff"
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            Best seller
                                                        </div>
                                                        <div
                                                            :class="
                                                                product
                                                                    ? 'recommended-tag-mobile'
                                                                    : 'recommended-only-mobile'
                                                            "
                                                            v-if="
                                                                product.recommended
                                                            "
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18.412"
                                                                height="13.267"
                                                                viewBox="0 0 18.412 13.267"
                                                                class="recommended"
                                                            >
                                                                <g
                                                                    id="noun-recommended-1397309"
                                                                    transform="translate(-154.397 -140.221)"
                                                                >
                                                                    <path
                                                                        id="Path_2714"
                                                                        data-name="Path 2714"
                                                                        class="cls-1"
                                                                        d="M252.333,147.993a1.176,1.176,0,0,0-.493-.966,1.178,1.178,0,0,0,.493-.967,1.133,1.133,0,0,0-1.1-1.16H248.79a18,18,0,0,0,.647-2.652,1.91,1.91,0,0,0-.238-1.523,1,1,0,0,0-.79-.3c-.463,0-.6.552-.786,1.316-.089.362-.19.773-.34,1.215a9.785,9.785,0,0,1-.988,1.962l-.039.006a1.223,1.223,0,0,0-.983,1.037h-2.085v-.807a.194.194,0,0,0-.194-.194h-4.629a.194.194,0,1,0,0,.387H242.8V152.9h-4.435a.194.194,0,1,0,0,.387H243a.194.194,0,0,0,.194-.194v-.607h2.264a.16.16,0,0,0,.016,0,1.222,1.222,0,0,0,1.012.539h4.75a1.133,1.133,0,0,0,1.1-1.161,1.177,1.177,0,0,0-.493-.966,1.2,1.2,0,0,0,0-1.934,1.182,1.182,0,0,0,.493-.969Zm-7.074,4.107h-2.07v-5.756h2.07Zm5.973-3.333a.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547h-4.75a.836.836,0,0,1-.835-.835v-5.679a.836.836,0,0,1,.682-.82.674.674,0,0,1,.087-.007.2.2,0,0,0,.153-.088,10.36,10.36,0,0,0,1.082-2.129c.156-.458.258-.878.349-1.248.123-.5.251-1.021.41-1.021a.611.611,0,0,1,.5.171,1.647,1.647,0,0,1,.146,1.217,18.746,18.746,0,0,1-.712,2.838.193.193,0,0,0,.184.253h2.705a.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547Z"
                                                                        transform="translate(-79.724)"
                                                                    />
                                                                    <path
                                                                        id="Path_2715"
                                                                        data-name="Path 2715"
                                                                        class="cls-1"
                                                                        d="M202.044,258.2h3.011a.194.194,0,0,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                                                                        transform="translate(-45.076 -111.98)"
                                                                    />
                                                                    <path
                                                                        id="Path_2716"
                                                                        data-name="Path 2716"
                                                                        class="cls-1"
                                                                        d="M212.784,291.41a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(-55.321 -144.03)"
                                                                    />
                                                                    <path
                                                                        id="Path_2717"
                                                                        data-name="Path 2717"
                                                                        class="cls-1"
                                                                        d="M202.044,325.4h3.011a.194.194,0,1,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                                                                        transform="translate(-45.076 -176.083)"
                                                                    />
                                                                    <path
                                                                        id="Path_2718"
                                                                        data-name="Path 2718"
                                                                        class="cls-1"
                                                                        d="M212.784,358.61a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(-55.321 -208.133)"
                                                                    />
                                                                    <path
                                                                        id="Path_2719"
                                                                        data-name="Path 2719"
                                                                        class="cls-1"
                                                                        d="M156.134,291.41H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,0,0,0-.387Z"
                                                                        transform="translate(0 -144.03)"
                                                                    />
                                                                    <path
                                                                        id="Path_2720"
                                                                        data-name="Path 2720"
                                                                        class="cls-1"
                                                                        d="M156.134,358.61H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,1,0,0-.387Z"
                                                                        transform="translate(0 -208.133)"
                                                                    />
                                                                    <path
                                                                        id="Path_2721"
                                                                        data-name="Path 2721"
                                                                        class="cls-1"
                                                                        d="M205.254,392.4a.194.194,0,0,0-.194-.194h-3.011a.194.194,0,1,0,0,.387h3.011A.193.193,0,0,0,205.254,392.4Z"
                                                                        transform="translate(-45.081 -240.185)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            Recommended by
                                                            Siloho
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mobile-product-body"
                                                    >
                                                        <div
                                                            class="mobile-product-details"
                                                        >
                                                            <p
                                                                class="prod-name"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        15,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            15
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="prod-vendor"
                                                            >
                                                                {{
                                                                    product.vendor
                                                                }}
                                                            </p>
                                                            <p
                                                                class="prod-price"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        product.item_price,
                                                                    )
                                                                }}
                                                            </p>
                                                            <div
                                                                class="mobile-actions"
                                                            >
                                                                <div
                                                                    class="mobile-add"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                product: product,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                operation:
                                                                                    'restore',
                                                                                enable: false,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    + ADD TO
                                                                    ROOM
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="mobile-product-image"
                                                        >
                                                            <img
                                                                :src="
                                                                    product.image_url
                                                                        ? product.image_url
                                                                        : require('@/assets/images/default-product-img.png')
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            product.available ===
                                                                false
                                                        "
                                                        class="mobile-unavailble"
                                                    >
                                                        Product Unavailable
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab
                                            :title="`Services`"
                                            :verticalTab="true"
                                            v-if="
                                                roomData[currentState]
                                                    .removedServices.length > 0
                                            "
                                        >
                                            <div class="services-active">
                                                <List
                                                    type="Services"
                                                    :obj="{
                                                        active: false,
                                                    }"
                                                    :List="
                                                        roomData[currentState]
                                                            .removedServices
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                    @showDescirption="
                                                        showServiceDescription
                                                    "
                                                />
                                                <div
                                                    v-for="(service,
                                                    index) in roomData[
                                                        currentState
                                                    ].removedServices"
                                                    :key="index"
                                                    class="single-service-mobile"
                                                >
                                                    <div
                                                        class="service-category"
                                                    >
                                                        <img
                                                            :src="
                                                                service.icon_url
                                                                    ? service.icon_url
                                                                    : require('@/assets/images/enable_service.png')
                                                            "
                                                            class="service-image"
                                                        />
                                                        <p class="service-type">
                                                            {{ service.type }}
                                                        </p>
                                                        <div class="actions">
                                                            <div
                                                                class="action-remove"
                                                                @click="
                                                                    checkDraft({
                                                                        operation:
                                                                            'allService',
                                                                        type:
                                                                            service.type,
                                                                        scene_id:
                                                                            scene.id,
                                                                        enable: true,
                                                                        ehd_id:
                                                                            EHDDetails.id,
                                                                    })
                                                                "
                                                            >
                                                                + Add
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="service-info "
                                                        :class="
                                                            idx === 0
                                                                ? ''
                                                                : 'service-hr'
                                                        "
                                                        v-for="(services,
                                                        idx) in service.data"
                                                        :key="services.id"
                                                    >
                                                        <div
                                                            class="service-details"
                                                        >
                                                            <p
                                                                class="service-name"
                                                            >
                                                                {{
                                                                    services.name
                                                                }}
                                                            </p>
                                                            <div
                                                                class="actions"
                                                            >
                                                                <div
                                                                    class="action-remove"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                operation:
                                                                                    'service',
                                                                                service_id:
                                                                                    services.id,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                enable: true,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    + Add
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="service-price"
                                                        >
                                                            <p
                                                                class="service-data"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        services.rate,
                                                                    )
                                                                }}
                                                                {{
                                                                    services.unit
                                                                }}
                                                            </p>
                                                            <p
                                                                class="service-data"
                                                            >
                                                                {{
                                                                    services.quantity
                                                                }}
                                                            </p>

                                                            <p
                                                                class="service-data"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        services.price,
                                                                    )
                                                                }}
                                                            </p>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                class="info-icon"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                @click="
                                                                    showServiceDescription(
                                                                        services.description,
                                                                    )
                                                                "
                                                            >
                                                                <g
                                                                    id="Group_2146"
                                                                    data-name="Group 2146"
                                                                    transform="translate(-930 -971)"
                                                                >
                                                                    <g
                                                                        id="Ellipse_608"
                                                                        data-name="Ellipse 608"
                                                                        class="cls-1"
                                                                        transform="translate(930 971)"
                                                                    >
                                                                        <circle
                                                                            class="cls-3"
                                                                            cx="8.5"
                                                                            cy="8.5"
                                                                            r="8.5"
                                                                        />
                                                                        <circle
                                                                            class="cls-4"
                                                                            cx="8.5"
                                                                            cy="8.5"
                                                                            r="7.85"
                                                                        />
                                                                    </g>
                                                                    <text
                                                                        id="i"
                                                                        class="cls-2"
                                                                        transform="translate(937 984)"
                                                                    >
                                                                        <tspan
                                                                            x="0"
                                                                            y="0"
                                                                        >
                                                                            i
                                                                        </tspan>
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab
                                            :title="`Custom Products`"
                                            :verticalTab="true"
                                            v-if="
                                                roomData[currentState]
                                                    .removedCustomProducts
                                                    .length > 0
                                            "
                                            class="services-active"
                                        >
                                            <div class="products-active">
                                                <List
                                                    type="Products"
                                                    :obj="{
                                                        view: false,
                                                    }"
                                                    :List="
                                                        roomData[currentState]
                                                            .removedCustomProducts
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                                <div
                                                    v-for="(product,
                                                    indx) in roomData[
                                                        currentState
                                                    ].removedCustomProducts"
                                                    :key="indx"
                                                    class="single-product-mobile"
                                                >
                                                    <div
                                                        class="mobile-product-body"
                                                    >
                                                        <div
                                                            class="mobile-product-details"
                                                        >
                                                            <p
                                                                class="prod-name"
                                                            >
                                                                {{
                                                                    product.name.slice(
                                                                        0,
                                                                        15,
                                                                    )
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        product
                                                                            .name
                                                                            .length >
                                                                            15
                                                                    "
                                                                    >...</span
                                                                >
                                                            </p>
                                                            <p
                                                                class="prod-vendor"
                                                            >
                                                                {{
                                                                    product.vendor
                                                                }}
                                                            </p>
                                                            <p
                                                                class="prod-price"
                                                                :class="
                                                                    requiredDomain ==
                                                                    false
                                                                        ? 'blur'
                                                                        : ''
                                                                "
                                                            >
                                                                ₹{{
                                                                    convertToIndianNumberSystem(
                                                                        product.price,
                                                                    )
                                                                }}
                                                            </p>
                                                            <div
                                                                class="mobile-actions"
                                                            >
                                                                <div
                                                                    class="mobile-add"
                                                                    @click="
                                                                        checkDraft(
                                                                            {
                                                                                product: product,
                                                                                scene_id:
                                                                                    scene.id,
                                                                                operation:
                                                                                    'customProduct',
                                                                                enable: true,
                                                                                ehd_id:
                                                                                    EHDDetails.id,
                                                                            },
                                                                        )
                                                                    "
                                                                >
                                                                    + ADD
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="mobile-custom-image"
                                                        >
                                                            <img
                                                                :src="
                                                                    product.image_url
                                                                        ? product.image_url
                                                                        : require('@/assets/images/default-product-img.png')
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div class="ehd-details">
                        <div class="finalise-div">
                            <p>
                                Total Cost:
                                <span
                                    v-if="$route.query.ok"
                                    :class="
                                        requiredDomain == false ? 'blur' : ''
                                    "
                                >
                                    ₹
                                    {{
                                        convertToIndianNumberSystem(
                                            kitchenPrice,
                                        )
                                    }}
                                </span>
                                <span v-else>
                                    ₹
                                    {{ convertToIndianNumberSystem(totalCost) }}
                                </span>
                            </p>
                            <p>Estimated Completion 4 weeks</p>
                            <router-link
                                :to="{
                                    name: 'FinaliseAndPlaceOrderPage',
                                    query: {
                                        ehd_id: EHDDetails.id,
                                        scene_id: EHDDetails.scene_data[0].id,
                                    },
                                }"
                            >
                                <Button
                                    name="Finalize"
                                    primary
                                    class="finalise-button"
                                />
                            </router-link>
                        </div>
                        <img
                            :src="
                                EHDDetails.designer_image
                                    ? EHDDetails.designer_image
                                    : require('assets/images/default-designer.png')
                            "
                            :alt="EHDDetails.designer"
                            class="designer-image"
                        />
                        <p class="detail-text">
                            Designed by {{ EHDDetails.designer }}
                        </p>
                        <p class="detail-text">
                            Project Name: {{ EHDDetails.project_name }}
                        </p>
                        <p class="detail-text">
                            Layout Type: {{ EHDDetails.floor_plan
                            }}<span v-if="EHDDetails.carpet_area"
                                >({{ EHDDetails.carpet_area }})</span
                            >
                        </p>
                        <p class="detail-text">
                            {{ EHDDetails.scene_data[currentState].room_type }}
                            |
                            <span :class="requiredDomain == false ? 'blur' : ''"
                                >₹{{
                                    convertToIndianNumberSystem(
                                        roomData[currentState].serviceCost +
                                            roomData[currentState].productCost,
                                    )
                                }}</span
                            >
                            |
                            {{ EHDDetails.theme }}
                        </p>

                        <div class="total-price">
                            <p>
                                Included Products({{
                                    roomData[currentState].activeProductCount
                                }})<span
                                    :class="
                                        requiredDomain == false ? 'blur' : ''
                                    "
                                    >₹{{
                                        convertToIndianNumberSystem(
                                            roomData[currentState].productCost,
                                        )
                                    }}</span
                                >
                            </p>
                            <p>
                                Included Services ({{
                                    roomData[currentState].activeServiceCount
                                }})<span
                                    :class="
                                        requiredDomain == false ? 'blur' : ''
                                    "
                                    >₹{{
                                        convertToIndianNumberSystem(
                                            roomData[currentState].serviceCost,
                                        )
                                    }}</span
                                >
                            </p>
                            <p class="total-cost">
                                Total Cost<span
                                    :class="
                                        requiredDomain == false ? 'blur' : ''
                                    "
                                    >₹{{
                                        convertToIndianNumberSystem(
                                            roomData[currentState].serviceCost +
                                                roomData[currentState]
                                                    .productCost,
                                        )
                                    }}</span
                                >
                            </p>
                        </div>

                        <Button
                            v-if="
                                EHDDetails.scene_data[currentState]
                                    .scene_type === 'WHITE'
                            "
                            name="customise"
                            orange
                            class="customisation-button"
                            :data-scene-id="
                                EHDDetails.scene_data[currentState].id
                            "
                            @click.native="
                                checkDraft({
                                    ehd_id: EHDDetails.id,
                                    scene_id:
                                        EHDDetails.scene_data[currentState].id,
                                    operation: 'customise',
                                    scene:
                                        EHDDetails.scene_data[currentState]
                                            .room_type,
                                    scene_img:
                                        EHDDetails.scene_data[currentState]
                                            .scene_image_data[0].length != 0
                                            ? EHDDetails.scene_data[
                                                  currentState
                                              ].scene_image_data[0]
                                                  .scene_image_url
                                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',
                                })
                            "
                        />
                    </div>
                </div>
                <Modal
                    v-if="showDescription"
                    @close="showDescription = false"
                    transparent
                >
                    <div class="service-description">
                        <p>Description</p>
                        {{ serviceDescription }}
                    </div>
                </Modal>
                <Modal
                    v-if="showRestoreModal"
                    @close=";(showRestoreModal = false), (countOfProduct = 1)"
                    transparent
                >
                    <div class="removed-products-popup">
                        <p>
                            {{ draftObject.product.name.slice(0, 50)
                            }}<span v-if="draftObject.product.name.length > 50"
                                >...</span
                            >
                        </p>
                        <div class="number-of-products">
                            <div
                                class="remove-one"
                                v-if="countOfProduct != 1"
                                @click="countOfProduct--"
                            >
                                <OperationsSVG operation="minus" />
                            </div>
                            <div v-else class="disable-icon">
                                <OperationsSVG operation="minus" />
                            </div>

                            <div class="total">
                                {{ countOfProduct }}
                            </div>

                            <div class="add-one" @click="countOfProduct++">
                                <OperationsSVG operation="plus" />
                            </div>
                        </div>
                        <div
                            class="add-button"
                            @click="
                                restoreMultipleProducts({
                                    product: draftObject.product,
                                    scene_id: draftObject.scene_id,
                                    operation: 'addMultiple',
                                    enable: true,
                                    ehd_id: draftObject.ehd_id,
                                    quantity: countOfProduct,
                                })
                            "
                        >
                            + ADD
                        </div>
                    </div>
                </Modal>
            </template>

            <template v-if="showNotFound === true">
                <NotFound :data="'EHD'" :content="content" />
            </template>
            <FullPageNavModal
                v-if="showModal"
                @close="toggleFullPageModal"
                :sceneId="currentSceneId"
                :ehdId="ehdId"
                :theme="EHDDetails.theme"
                :requiredDomain="requiredDomain"
                @update="updateEHD"
            >
            </FullPageNavModal>

            <ReplaceProductModal
                v-if="replaceProductModal"
                :currentProductID="productID"
                :currentProductImage="
                    productImage
                        ? productImage
                        : require('@/assets/images/default-product-img.png')
                "
                :currentProductName="productName"
                :requiredDomain="requiredDomain"
                :currentProductPrice="productPrice"
                :currentProductShow="currentProductShow"
                :currentProductDescription="productDescription"
                :currentSceneId="currentSceneId"
                :add="add"
                @toggleReplaceProductModal="toggleReplaceProductModal"
                @changeProductForEHD="changeProductForEHD"
                @addProductForEHD="addProductForEHD"
                @closeLoader="closeLoader"
            />

            <Modal v-if="draftModal" @close="closeDraftModal" transparent>
                <div class="ehdmodal">
                    <div class="ehdmodal-view">
                        <p>{{ draftModalMsg }}</p>

                        <div
                            class="ehd-display"
                            v-if="showDraftVersions === true"
                        >
                            <template v-if="useWorkspaceComp === false">
                                <div class="draft-ehd">
                                    <p>My Draft</p>
                                    <div class="draft-details">
                                        <img
                                            :src="
                                                draftDetails.draft_scene
                                                    .scene_image
                                            "
                                        />
                                        <div class="draft-info">
                                            <p
                                                :class="
                                                    requiredDomain === false
                                                        ? 'blur'
                                                        : ''
                                                "
                                            >
                                                ₹{{
                                                    convertToIndianNumberSystem(
                                                        draftDetails.draft_scene
                                                            .price,
                                                    )
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.draft_scene
                                                        .no_of_products
                                                }}
                                                Products
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.draft_scene
                                                        .no_of_services
                                                }}
                                                Services
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="original-ehd">
                                    <p>Original</p>
                                    <div class="draft-details">
                                        <img
                                            :src="
                                                draftDetails.original_scene
                                                    .scene_image
                                            "
                                        />
                                        <div class="draft-info">
                                            <p
                                                :class="
                                                    requiredDomain === false
                                                        ? 'blur'
                                                        : ''
                                                "
                                            >
                                                ₹{{
                                                    convertToIndianNumberSystem(
                                                        draftDetails
                                                            .original_scene
                                                            .price,
                                                    )
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.original_scene
                                                        .no_of_products
                                                }}
                                                Products
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.original_scene
                                                        .no_of_services
                                                }}
                                                Services
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <div>
                                    <WorkspaceComponent
                                        :draftImage="
                                            draftDetails.draft_scene.scene_image
                                        "
                                        :theme="
                                            draftDetails.draft_scene.theme_name
                                        "
                                        :ogDetails="draftDetails.original_scene"
                                        :price="draftDetails.draft_scene.price"
                                        :footerName="
                                            draftDetails.draft_scene
                                                .display_name
                                        "
                                        :noOfProducts="
                                            draftDetails.draft_scene
                                                .no_of_products
                                        "
                                        :noOfServices="
                                            draftDetails.draft_scene
                                                .no_of_services
                                        "
                                        :space="
                                            draftDetails.draft_scene.space_name
                                        "
                                        :eta="draftDetails.draft_scene.ETA"
                                        :products="
                                            draftDetails.draft_scene
                                                .no_of_products
                                        "
                                        :timeCreated="
                                            draftDetails.draft_scene.updated_at
                                        "
                                        :sceneid="
                                            draftDetails.draft_scene.scene_id
                                        "
                                        :ehdid="
                                            draftDetails.draft_scene
                                                .draft_ehd_id
                                                ? draftDetails.draft_scene
                                                      .draft_ehd_id
                                                : null
                                        "
                                        :draftBySpace="true"
                                        :hideButtons="true"
                                    />
                                </div>
                            </template>
                        </div>

                        <div
                            class="result-button"
                            v-if="showDraftVersions === true"
                            style="margin-top: 20px; display: flex; justify-content: center;"
                        >
                            <Button
                                class="delete-draft"
                                name="Continue"
                                outline
                                @click.native="continueWithDraft"
                            />
                            <Button
                                style="margin-left:10px;"
                                class="override"
                                name="Restore"
                                primary
                                @click.native="restoreDraft"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal v-if="tryAgainModal" transparent @close="closeTryAgainModal">
                <div class="error-modal">
                    <div class="error-image">
                        <img src="@/assets/images/balloons.png" />
                    </div>
                    <p class="header">
                        Something went wrong
                    </p>
                    <p class="subhead">Please try again</p>
                    <div
                        class="result-button"
                        style="display: flex; justify-content: center;"
                    >
                        <Button
                            class="delete-draft"
                            name="Try again"
                            primary
                            @click.native="tryApiAgain"
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                v-if="showEhdModal"
                class="scene-image-modal"
                @close="showFullEhdModal"
                transparent
                :closeIn="false"
            >
                <img
                    class="fimage"
                    :src="
                        default_scene_image !== ''
                            ? default_scene_image
                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                    "
                />
            </Modal>

            <template v-if="loaderShow">
                <div class="wait">
                    <Loader :loaderType="loaderType" />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getDraft, deleteDraft, updateDraft } from 'api/userApi'
import {
    toggleProduct,
    // removeProduct,
    // toggleService,
    changeProduct as changeProductApi,
    // addProduct,
    getProductsBySearch,
    customise_scene,
} from 'api/productsApi'
import CustomisationPageModule from 'store/modules/CustomisationPage'
import ProductListPageModule from 'store/modules/ProductListPage'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import EHDPageModule from 'store/modules/EHDPage'
import AuthModule from 'store/modules/$authModule'
import Button from 'componentsv2/Button'
import Tabs from 'componentsv2/Tabs'
import NotFound from 'componentsv2/NotFound'
import Tab from 'componentsv2/Tab'
//import Cards from 'componentsv2/Cards'
import NewCard from 'componentsv2/NewCard'
import FullPageNavModal from 'componentsv3/FullPageNavModal'
import VirtualButtonSVG from 'componentsv3/SVG/VirtualButtonSVG'
import EhdMoreImageButtonSVG from 'componentsv3/SVG/EhdMoreImageButtonSVG'
import CaretSVG from 'componentsv3/SVG/CaretSVG'
import EhdFullScreenSVG from 'componentsv3/SVG/EhdFullScreenSVG'
import CategoryListViewSVG from 'componentsv3/SVG/CategoryListViewSVG'
import OperationsSVG from 'componentsv3/SVG/OperationsSVG'
import List from 'componentsv3/List'
import WarningBanner from 'componentsv3/WarningBanner'
import HighlightSVG from 'componentsv3/SVG/HighlightSVG'
import Modal from 'componentsv2/Modal'
import Loader from 'componentsv2/Loader'
import FullPageModal from 'componentsv2/FullPageModal'
import CurrentProduct from 'componentsv2/CurrentProduct'
import ProductCards from 'componentsv2/ProductCards'
import FiltersBox from 'componentsv2/FiltersBox'
import RequestProduct from 'componentsv2/RequestProduct'
import SearchAutoComplete from 'componentsv2/SearchAutoComplete'
import CustomizeKitchenModule from 'store/modules/CustomizeKitchenPage'
import { getEHDDetailsv3, submitKitchenData } from 'api/ehdApi'
import { toggleOnlyKitchen } from 'api/userApi'
// import Carousel from 'componentsv2/Carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import WorkspaceComponent from 'componentsv2/WorkspaceComponent'
import loaderHandler from 'mixins/loader'
import ReplaceProductModal from 'componentsv2/ReplaceProductModal'
import ProductFilters from 'componentsv2/ProductFilters'
import ProductFilterMobile from 'componentsv2/ProductFilterMobile'

export default {
    name: 'EHDPage',
    components: {
        Button,
        Tabs,
        Tab,
        //Cards,
        FullPageNavModal,
        Modal,
        Loader,
        // NewCard,
        NotFound,
        // FullPageModal,
        VirtualButtonSVG,
        EhdMoreImageButtonSVG,
        CaretSVG,
        EhdFullScreenSVG,
        CategoryListViewSVG,
        OperationsSVG,
        // HighlightSVG,
        // CurrentProduct,
        // ProductCards,
        // FiltersBox,
        // RequestProduct,
        SearchAutoComplete,
        List,
        WarningBanner,
        ReplaceProductModal,
        // Carousel,
        WorkspaceComponent,
        // ProductFilters,
        // ProductFilterMobile,
    },
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    props: {
        requiredDomain: Boolean,
    },
    data() {
        return {
            settings: {
                dots: true,
                dragabble: true,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            settings1: {
                dots: true,
                // autoplay: true,
                autoplaySpeed: 2800,
                arrows: false,
                prevArrow: false,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            showNotFound: false,
            // loaderShow: false,
            showModal: false,
            callDraft: false,
            draftModal: false,
            draftModalMsg: '',
            ehdId: this.$route.params.id ? this.$route.params.id : null,
            currentSceneId: null,
            draftEHDId: null,
            draftSceneId: null,
            seeMoreActivated: false,
            scrollPosition: 0,
            replaceProductModal: false,
            productID: null,
            productImage: null,
            productName: null,
            productPrice: null,
            productDescription: null,
            draftExists: null,
            quantity: null,
            countAltered: false,
            currentProductShow: true,
            scene_id: null,
            add: false,
            autocomplete: '',
            suggestions: [],
            currentState: 0,
            currentTab: 0,
            //paging for related products
            pageNumber: 1,
            totalNumberOfPages: '',
            similarProducts: null,
            autoLoad: true,
            filter_str: '',
            default_scene_image: '',
            scene_thumbnails: [],
            showThumbnails: false,
            //paging for search products
            searchNumber: 1,
            totalNumberOfSearchPages: '',
            searchingActive: true,
            //Checking which operation called while checking draft
            operation: '',
            draftObject: {},
            showDraftVersions: false,
            useWorkspaceComp: false,
            draftDetails: {},
            checkDraftExists: false,
            content: 'Not allowed to access this EHD,',
            roomsAvailable: [],
            kitchenPrice: 0,
            displayView: false,
            sceneData: [],
            serviceDescription: '',
            showDescription: false,
            queryString: '',
            addProducts: false,
            showRemovedOverlay: false,
            showRestoreModal: false,
            countOfProduct: 1,
            productCategoryName: 'none',
            currentTabTitle: '',
            showEhdModal: false,
            productInfo: {},
            newproductid: 0,
            tryAgainModal: false,
            tryAgainObject: {},
            showMobileFilter: false,
            selectedFilters: [],
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.AuthModule.isLoggedIn,
            userData: state => state.AuthModule.userData,
            showAuthModal: state => state.AuthModule.showAuthModal,
            EHDDetails: state => state.EHDPage.EHDDetails,
            ehdShowModal: state => state.EHDPage.ehdShowModal,
            ehdModalMsg: state => state.EHDPage.ehdModalMsg,
            windowWidth: state => state.AppModule.windowWidth,
            allSceneData: state => state.CustomisationPage.scene,
            scene: state => state.CustomisationPage.scene.scene_dsetTimeoutata,
            relatedProducts: state => state.CustomisationPage.relatedProducts,
            searchedProducts: state => state.CustomisationPage.searchedProducts,
            totalProducts: state => state.EHDPage.totalProducts,
            totalServices: state => state.EHDPage.totalServices,
            totalCustomProducts: state => state.EHDPage.totalCustomProducts,
            baseProducts: state => state.EHDPage.baseProducts,
            products: state => state.EHDPage.products,
            categoryGroups: state => state.EHDPage.categoryGroups,
            roomData: state => state.EHDPage.roomData,
            totalCost: state => state.EHDPage.totalCost,
            filters: state => state.ProductListPage.filters_data,
            productsList: state => state.ProductListPage.productsList,
        }),
        removedProduct() {
            return this.roomData[this.currentState].removedProducts
        },
    },
    watch: {
        isLoggedIn(e) {
            this.fetchEHDDetailsv3(this.$route.params.id)
        },
        showRemovedOverlay(e) {
            if (e === true) {
                setTimeout(() => {
                    this.showRemovedOverlay = false
                }, 6000)
            }
        },
        $route(to, from) {
            if (to !== from) {
                this.fetchEHDDetailsv3(this.$route.params.id).then(payload => {
                    if (payload.responseCode === 200) {
                        this.setDefaultThumbnails(payload.data.scene_data)
                        this.loaderShow = false

                        if (this.$route.query.scene) {
                            payload.data.scene_data.forEach((scene, index) => {
                                if (scene.id == this.$route.query.scene) {
                                    this.currentState = index
                                }
                            })
                        }
                        if (this.$route.query.ok) {
                            payload.data.scene_data.forEach((scene, index) => {
                                let obj = {
                                    room_available: '',
                                    scene: scene.id,
                                }
                                if (scene.room_type === 'Kitchen') {
                                    obj.room_available = true
                                    this.currentState = index
                                    this.kitchenPrice = scene.price
                                } else {
                                    obj.room_available = false
                                }
                                this.roomsAvailable.push(obj)
                            })
                        } else {
                            payload.data.scene_data.forEach((scene, index) => {
                                let obj = {
                                    room_available: true,
                                    scene: scene.id,
                                }
                                this.roomsAvailable.push(obj)
                            })
                        }
                    } else {
                        this.loaderShow = false
                        this.showNotFound = true
                    }
                })
            }
        },
    },
    metaInfo: {
        title: 'SILOHO',
        titleTemplate: '%s | EHD Page',
        bodyAttrs: {
            class: ['ehd-page-body'],
        },
    },
    created() {
        this.registerStoreModule('EHDPage', EHDPageModule)
        this.registerStoreModule('CustomizeKitchenPage', CustomizeKitchenModule)
        // this.loaderShow = true

        this.registerStoreModule('CustomisationPage', CustomisationPageModule)
        this.registerStoreModule('ProductListPage', ProductListPageModule)
        this.fetchEHDDetailsv3(this.$route.params.id).then(payload => {
            if (payload.responseCode === 200) {
                this.loaderShow = false

                this.setDefaultThumbnails(payload.data.scene_data)

                if (this.$route.query.scene) {
                    payload.data.scene_data.forEach((scene, index) => {
                        if (scene.id == this.$route.query.scene) {
                            this.currentState = index
                        }
                    })
                }
                if (this.$route.query.ok) {
                    payload.data.scene_data.forEach((scene, index) => {
                        let obj = { room_available: '', scene: scene.id }
                        if (scene.room_type === 'Kitchen') {
                            obj.room_available = true
                            this.currentState = index
                            this.kitchenPrice = scene.price
                        } else {
                            obj.room_available = false
                        }
                        this.roomsAvailable.push(obj)
                    })
                } else {
                    payload.data.scene_data.forEach((scene, index) => {
                        let obj = { room_available: true, scene: scene.id }
                        this.roomsAvailable.push(obj)
                    })
                }
            } else {
                this.loaderShow = false
                this.showNotFound = true
            }
        })
    },
    mounted() {
        if (this.$route.query.scene_id) {
            this.checkDraft(this.$route.query.scene_id)
            window.addEventListener('scroll', this.hideHeader)
        }
    },
    methods: {
        ...mapActions({
            fetchEHDDetailsv3: 'fetchEHDDetailsv3',
            toggleEhdModal: 'toggleEhdModal',
            toggleShowAuthModal: 'toggleShowAuthModal',
            fetchFilters: 'fetchFilters',
            fetchScene: 'fetchScene',
            fetchRelatedProducts: 'fetchRelatedProducts',
            fetchSearchedProducts: 'fetchSearchedProducts',
            fetchProducts: 'fetchProducts',
            updateRelatedProducts: 'updateRelatedProducts',
            updateSearchedProducts: 'updateSearchedProducts',
            CLIENT_SCENE: 'CLIENT_SCENE',
            restoreDraftDetailsv3: 'restoreDraftDetailsv3',
            fetchQuoteList: 'fetchQuoteList',
            changeBaseProductCount: 'changeBaseProductCount',
            removeProduct: 'removeProduct',
            toggleCustomProduct: 'toggleCustomProduct',
            toggleService: 'toggleService',
            addProduct: 'addProduct',
            addNewProduct: 'addNewProduct',
            replaceBaseProduct: 'replaceBaseProduct',
            addAllProducts: 'addAllProducts',
            fetchFilter: 'fetchFilter',
            fetchAllProducts: 'fetchAllProducts',
        }),
        onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            //If last element reached then load api for more products

            if (scrollTop > this.prevScroll) {
                //scrolled up
                this.direction = 2
            } else if (scrollTop < this.prevScroll) {
                //scrolled down
                this.direction = 1
            }

            if (this.direction !== this.prevDirection) {
                this.toggleHeader(this.direction, scrollTop)
            }
            this.prevScroll = scrollTop
            if (scrollTop < 25) {
                document.querySelector('.productlist-head').style.paddingTop =
                    '0px'
            } else {
                document.querySelector('.productlist-head').style.paddingTop =
                    '15px'
            }
        },
        //function to add class hide for searchbar and close button
        toggleHeader(direction, curScroll) {
            var header = document.querySelector('.productlist-head')
            var closeIcon = document.querySelector('.close')
            if (direction === 2 && curScroll > 10) {
                //replace 52 with the height of your header in px
                header.classList.add('hide')
                closeIcon.classList.add('hide')
                this.prevDirection = direction
            } else if (direction === 1) {
                header.classList.remove('hide')
                closeIcon.classList.remove('hide')
                this.prevDirection = direction
            }
        },
        closeTryAgainModal() {
            this.tryAgainModal = false
        },
        showFullEhdModal(val) {
            this.showEhdModal = val
            document.querySelector('body').style.overflow = this.showEhdModal
                ? 'hidden'
                : 'auto'
        },
        displayViewByToggle(bool) {
            this.displayView = bool
        },
        toggleSceneThumbnails() {
            this.showThumbnails = !this.showThumbnails
        },
        hideThumbnails(bool) {
            if (bool === true) {
                this.showThumbnails = false
            }
        },
        setDefaultThumbnails(scene_data) {
            this.scene_thumbnails = []
            scene_data.forEach(scene => {
                var temp_images = []
                scene.scene_image_data.forEach(image => {
                    temp_images.push({
                        image: image.scene_image_url,
                        scene_image_type: image.scene_image_type,
                    })
                })
                this.scene_thumbnails.push(temp_images)
            })
            this.default_scene_image =
                this.scene_thumbnails[this.currentState].length > 0
                    ? this.scene_thumbnails[this.currentState][0].image
                    : ''
        },
        changeDefaultSceneImage(image) {
            this.default_scene_image = image
        },
        addAllRooms(id) {
            this.roomsAvailable = []
            toggleOnlyKitchen(false).then(response => {
                if (response.responseCode === 200) {
                    this.$router.push({ path: `/ehd/${id}` })
                }
            })
        },
        restoreProduct() {
            this.loaderShow = true
            this.showRemovedOverlay = false
            let obj
            if (this.productInfo.product_enable == false) {
                obj = {
                    action: 'toggle_custom_product',
                    product_id: this.productInfo.product_id,
                    scene_id: this.productInfo.scene_id,
                    product_enable: true,
                }
            } else {
                obj = {
                    action: 'add_to_scene',
                    product_id: this.productInfo.product_id,
                    scene_id: this.productInfo.scene_id,
                    quantity: this.productInfo.quantity,
                }
            }
            this.tryAgainObject = obj

            customise_scene(obj)
                .then(response => {
                    if (response.responseCode === 200) {
                        this.addProduct(response)
                        this.tryAgainModal = false
                    } else {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(err => {
                    this.loaderShow = false
                    this.tryAgainModal = true
                    document.querySelector('body').style.overflow = 'auto'
                })
        },
        loadMoreProducts() {
            const spinner = `<div class="spinner"></div>`
            let loaderContainer = document.querySelector('.loader-container')
            if (
                this.pageNumber < this.totalNumberOfPages &&
                this.autoLoad === true
            ) {
                this.autoLoad = false
                this.pageNumber++
                loaderContainer.innerHTML += spinner
                this.fetchAllProducts({
                    filters: this.selectedFilters,
                    page: 1,
                    size: 20,
                }).then(response => {
                    if (response.responseCode == 200) {
                        this.totalNumberOfPages = response.payload.total_page
                        this.similarProducts = response.payload.products
                        loaderContainer.removeChild(
                            document.querySelector('.spinner'),
                        )
                        response.payload.products.forEach(product => {
                            this.similarProducts.push(product)
                        })
                        this.autoLoad = true
                    }
                })
            }
        },
        updateCurrentState(index) {
            this.currentState != index ? (this.currentTab = 0) : this.currentTab
            this.currentState = index
            this.default_scene_image =
                this.scene_thumbnails[this.currentState].length > 0
                    ? this.scene_thumbnails[this.currentState][0].image
                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
            this.showThumbnails = false
            this.queryString = ''
            this.addProducts = false
            if (this.currentTabTitle === 'Services') {
                if (
                    this.EHDDetails.scene_data[this.currentState].services
                        .length === 0
                ) {
                    this.currentTab = 1
                }
            } else if (this.currentTabTitle === 'Custom Products') {
                if (
                    this.EHDDetails.scene_data[this.currentState]
                        .inactive_one_time_custom_product.length === 0
                ) {
                    this.currentTab = 0
                    this.currentTabTitle = 'Included Products'
                }
            } else if (this.currentTabTitle === 'Included Products') {
                this.currentTab = 0
            } else if (this.currentTabTitle === 'Included Services') {
                this.currentTab = 1
            } else {
                if (
                    !this.EHDDetails.scene_data[this.currentState].products[
                        this.currentTabTitle
                    ]
                ) {
                    this.currentTab = 0
                    this.currentTabTitle = 'Included Products'
                }
            }

            this.updateRelatedProducts()
        },
        updateCurrentTab(index) {
            this.currentTab = index
            if (
                this.currentTabTitle === 'Included Services' ||
                this.currentTabTitle === 'Services' ||
                this.currentTabTitle === 'Custom Products' ||
                this.currentTab === -1
            ) {
                this.displayView = false
            }
            this.queryString = ''
            this.addProducts = false
            this.updateRelatedProducts()
        },
        updateCurrentTabTitle(title) {
            this.currentTabTitle = title
        },
        fetchSuggestions(query) {
            if (query.length > 2) {
                this.suggestions = []
                this.searchNumber = 1
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    this.suggestions = response.furnish_data
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },
        fetchSearchedProductsOrServices(query) {
            if (query.length > 0) {
                this.currentTab = -1
                this.queryString = query
                this.fetchSearchedProducts({
                    scene: this.EHDDetails.scene_data[this.currentState].id,
                    query: this.queryString,
                }).then(response => {
                    if (
                        response.payload.base_products.length === 0 &&
                        response.payload.products.length === 0
                    ) {
                        this.addProducts = true
                    } else {
                        this.addProducts = false
                    }
                })
            } else {
                this.currentTab = 0
                this.queryString = ''
                this.updateSearchedProducts()
            }
        },
        loadMoreSearchOptions(query) {
            if (
                this.searchNumber < this.totalNumberOfSearchPages &&
                this.searchingActive === true
            ) {
                this.searchingActive = false
                this.searchNumber++
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    response.furnish_data.forEach(product => {
                        this.suggestions.push(product)
                    })
                    this.searchingActive = true
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },

        toggleReplaceProductModal() {
            this.replaceProductModal = !this.replaceProductModal
            if (this.replaceProductModal) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
                this.updateRelatedProducts()
                this.similarProducts = {}
                this.totalNumberOfPages = null
                this.pageNumber = 1
                this.filter_str = ''
                this.suggestions = []
                this.totalNumberOfSearchPages = null
            }
        },
        

        changeProducts(obj) {
            this.add = false
            this.currentProductShow = true
            this.quantity = obj.total_quantity
            this.currentSceneId = obj.scene_id
            this.ehdId = this.$route.params.id
            this.productID = obj.product.id
            this.productIndex = obj.index
            this.productName = obj.product.name
            this.productDescription = obj.product.description
            this.productPrice = obj.product.item_price
            this.productImage = obj.product.image_url
            this.toggleReplaceProductModal()
            
            //

            //     this.loaderShow = false
            // this.fetchFilter(this.productID)
            // this.fetchRelatedProducts({
            //     obj: `?product_id=${this.productID}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.currentSceneId}`,
            // }).then(response => {
            //     if (response.responseCode === 200) {
            //         this.totalNumberOfPages = response.payload.total_page
            //         this.similarProducts = response.payload.related_product
            //     }
            // })
        },
        showServiceDescription(description) {
            this.showDescription = true
            this.serviceDescription = description
        },
        changeProductForEHD(newproduct) {
            this.loaderShow = true
            this.toggleReplaceProductModal()
            this.newproductid = newproduct.product_id
            this.tryAgainObject = {
                action: 'change_product',
                scene_id: this.draftObject.scene_id,
                old_product_id: this.draftObject.product.id,
                new_product_id: newproduct.product_id,
                details_id: this.draftObject.product.specific_data[0].id,
            }
            customise_scene({
                action: 'change_product',
                scene_id: this.draftObject.scene_id,
                old_product_id: this.draftObject.product.id,
                new_product_id: newproduct.product_id,
                details_id: this.draftObject.product.specific_data[0].id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.replaceBaseProduct(response)
                    } else {
                        this.tryAgainModal = true
                    }
                    this.loaderShow = false
                })
                .catch(() => {
                    this.loaderShow = false
                    this.tryAgainModal = true
                })
        },
        tryApiAgain() {
            this.tryAgainModal = false
            this.loaderShow = true
            customise_scene(this.tryAgainObject)
                .then(response => {
                    if (response.responseCode === 200) {
                        this.updateEHD({
                            ehd_id: response.payload.ehd_id,
                            scene_id: response.payload.scene_id,
                            callDraft: true,
                        })
                        this.loaderShow = false
                    } else {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    }
                })
                .catch(err => {
                    setTimeout(() => {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    }, 100)
                })
        },
        clearSearchedProducts() {
            this.queryString = ''
            this.addProducts = false
            this.currentTab = 0
            this.updateRelatedProducts()
        },
        showMobileFilters() {
            this.showMobileFilter = !this.showMobileFilter
        },
        getFilteredProduct(filter) {
            this.productLoader = true
            this.selectedFilters = filter
            this.filterObject = filter
            this.showMobileFilter = false
            this.pageNumber = 1
            this.fetchAllProducts({
                filters: filter,
                page: 1,
                size: 20,
            }).then(response => {
                if (response.responseCode == 200) {
                    this.productLoader = false
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.products
                }
            })
        },

        toggleProducts(obj) {
            this.loaderShow = true
            this.productCategoryName = obj.product.category_group
            document.querySelector('body').style.overflow = 'hidden'
            this.productInfo = {
                product_id: obj.product.id,
                scene_id: obj.scene_id,
                quantity: obj.product.active_quantity,
            }
            this.tryAgainObject = {
                action: 'disable_all_products',
                product_id: obj.product.id,
                scene_id: obj.scene_id,
            }
            customise_scene({
                action: 'disable_all_products',
                product_id: obj.product.id,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.removeProduct(response)
                        this.showRemovedOverlay = true
                        this.clearSearchedProducts()
                    } else {
                        this.tryAgainModal = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    this.tryAgainModal = true
                })
        },
        toggleCustomProducts(obj) {
            this.loaderShow = true
            if (obj.enable === false) {
                this.productCategoryName = 'Custom Products'
            } else {
                this.productCategoryName = 'Included Products'
                this.currentTab = 0
            }
            this.tryAgainObject = {
                action: 'toggle_custom_product',
                product_id: obj.product.prod_id,
                product_enable: obj.enable,
                scene_id: obj.scene_id,
            }
            this.productInfo = this.tryAgainObject
            document.querySelector('body').style.overflow = 'hidden'
            this.productInfo = {
                product_id: obj.product.prod_id,
                product_enable: obj.enable,
                scene_id: obj.scene_id,
            }
            customise_scene({
                action: 'toggle_custom_product',
                product_id: obj.product.prod_id,
                product_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.toggleCustomProduct(response)
                        this.showRemovedOverlay = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                })
        },

        changeCount(obj) {
            this.countAltered = false
            this.loaderShow = true
            let object
            document.querySelector('body').style.overflow = 'hidden'
            if (this.countAltered === false) {
                this.countAltered = true
                if (obj.enable === false) {
                    object = {
                        action: 'disable_product',
                        details_id: obj.product.specific_data.filter(
                            value => value.is_part_of_scene === true,
                        )[0].id,
                        scene_id: obj.scene_id,
                    }
                    this.productCategoryName = obj.product.category_group
                    this.productInfo = {
                        product_id: obj.product.id,
                        specific_id: obj.product.specific_data.filter(
                            value => value.is_part_of_scene === true,
                        )[0].id,
                        scene_id: obj.scene_id,
                        quantity: 1,
                    }
                } else {
                    object = {
                        action: 'add_to_scene',
                        product_id: obj.product.id,
                        scene_id: obj.scene_id,
                        quantity: 1,
                    }
                }
                this.tryAgainObject = object
                customise_scene(object)
                    .then(response => {
                        if (response.responseCode === 200) {
                            if (
                                response.payload.changes_data.product
                                    .active_quantity > 0
                            ) {
                                this.changeBaseProductCount(response)
                                this.fetchSearchedProductsOrServices(
                                    this.queryString,
                                )
                            } else {
                                this.removeProduct(response)
                                this.clearSearchedProducts()
                                if (obj.enable === false) {
                                    this.showRemovedOverlay = true
                                }
                            }
                        } else {
                            this.tryAgainModal = true
                        }
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                    })
                    .catch(() => {
                        this.loaderShow = false
                        this.tryAgainModal = true
                    })
            }
        },

        closeDraftModal() {
            this.loaderShow = false
            this.draftModal = false
            document.querySelector('body').style.overflow = 'auto'
        },

        toggleFullPageModal() {
            this.loaderShow = true
            this.showModal = !this.showModal
            document.querySelector('body').style.overflow = this.showModal
                ? 'hidden'
                : 'auto'
            this.loaderShow = false
        },

        updateEHD(e) {
            this.callDraft = e.callDraft
            this.ehdId = e.ehd_id
            this.currentSceneId = e.scene_id
            if (this.$route.params.id != e.ehd_id) {
                this.$router.push({ path: `/ehd/${e.ehd_id}` })
            } else {
                this.fetchEHDDetailsv3(this.$route.params.id).then(payload => {
                    this.setDefaultThumbnails(payload.data.scene_data)
                    window.scrollTo(0, this.scrollPosition)
                })
                // if (e.customisation === null) {
                //     this.loaderShow = false
                //     document.querySelector('body').style.overflow = 'auto'
                // }
            }
        },
        closeLoader(){
            this.loaderShow=false
        },
        addAllProductsToScene(obj) {
            this.showRestoreModal = false
            this.loaderShow = true
            this.productCategoryName = 'Included Products'
            this.tryAgainObject = {
                action: 'enable_all_products',
                scene_id: obj.scene_id,
                category_group: obj.category,
            }
            customise_scene({
                action: 'enable_all_products',
                scene_id: obj.scene_id,
                category_group: obj.category,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.addAllProducts(response)
                        this.currentTab = 0
                        this.showRemovedOverlay = true
                    } else {
                        this.tryAgainModal = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    this.tryAgainModal = true
                })
        },
        restoreMultipleProducts(obj) {
            this.showRestoreModal = false
            this.productCategoryName = 'Included Products'

            this.loaderShow = true
            this.tryAgainObject = {
                action: 'add_to_scene',
                product_id: obj.product.id,
                scene_id: obj.scene_id,
                quantity: obj.quantity,
            }
            customise_scene({
                action: 'add_to_scene',
                product_id: obj.product.id,
                scene_id: obj.scene_id,
                quantity: obj.quantity,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.addProduct(response)
                        this.currentTab = 0
                        this.showRemovedOverlay = true
                    } else {
                        this.tryAgainModal = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    this.tryAgainModal = true
                })
        },

        checkDraft(obj) {
            this.add = false
            this.loaderShow = true
            this.currentSceneId = obj.scene_id
            this.operation = obj.operation
            this.scene_type = obj.scene_type
            this.draftObject = obj
            this.scrollPosition = window.scrollY
            if (this.checkDraftExists === false) {
                this.checkDraftExists = true
                getDraft(obj.ehd_id, obj.scene_id).then(data => {
                    if (data.responseCode === 2012) {
                        this.checkDraftExists = false
                        switch (obj.operation) {
                            case 'change':
                                this.loaderShow = true
                                this.changeProducts(obj)
                                break
                            case 'add':
                                this.addMoreProducts({
                                    scene_id: obj.scene_id,
                                    scene_type: obj.scene_type,
                                })
                                break
                            case 'toggle':
                                this.toggleProducts(obj)
                                break
                            case 'customProduct':
                                this.toggleCustomProducts(obj)
                                break
                            case 'service':
                                this.toggleServices(obj)
                                break
                            case 'customise':
                                this.customiseScene()
                                break
                            case 'count':
                                this.changeCount(obj)
                                break
                            case 'restore':
                                this.showRestoreModal = true
                                this.loaderShow = false
                                break
                            case 'addAll':
                                this.addAllProductsToScene(obj)
                                break
                            case 'allService':
                                this.toggleAllServices(obj)
                                break
                            default:
                                break
                        }
                    } else {
                        this.useWorkspaceComp = data.data.use_ws_draft_comp
                        this.draftDetails = data.data.draft_info
                        this.draftObject.ehd_id = data.data.ehd_id
                        this.draftObject.scene_id = data.data.scene_id
                        this.showDraftVersions = true
                        document.querySelector('body').style.overflow = 'hidden'
                        this.draftModal = true
                        this.draftModalMsg =
                            "You have already made changes on this EHD. Would you like to 'continue' editing your draft or 'restore' previous changes?"
                        this.draftEHDId = data.data.ehd_id
                        this.draftSceneId = data.data.scene_id
                        this.currentSceneId = this.draftSceneId
                        this.ehdId = data.data.ehd_id
                        this.checkDraftExists = false
                        this.loaderShow = false
                    }
                })
            }
        },
        toggleAllServices(obj) {
            document.querySelector('body').style.overflow = 'hidden'
            customise_scene({
                action: 'toggle_services_by_type',
                service_id: obj.service_id,
                service_type: obj.type,
                service_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.ehdId = response.payload.ehd_id
                        this.newSceneId = response.payload.scene_id
                        this.toggleService(response)
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },

        toggleServices(obj) {
            document.querySelector('body').style.overflow = 'hidden'
            customise_scene({
                action: 'toggle_service',
                service_id: obj.service_id,
                service_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.ehdId = response.payload.ehd_id
                        this.newSceneId = response.payload.scene_id
                        this.toggleService(response)
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },

        customiseScene() {
            if (this.draftObject.scene === 'Kitchen') {
                const that = this
                this.fetchQuoteList(this.$route.params.id).then(response => {
                    if (response.responseCode === 200) {
                        // this.$router.push({name:'KitchenCustomisationPage',query: { eid:obj.ehd_id,sid:obj.scene_id} })
                        let obj_post = {
                            scene_id: this.draftObject.scene_id,
                            ehd_id: this.draftObject.ehd_id,
                            image: this.draftObject.scene_img,
                        }
                        let json_obj_post = JSON.stringify(obj_post)
                        submitKitchenData(json_obj_post).then(response => {
                            if (response.responseCode === 200) {
                                // this.$router.push({
                                //     name: 'EHDPage',
                                //     params: { id: response.payload.ehd_id },
                                // })
                                that.$router.push({
                                    name: 'KitchenCustomisationPage',
                                    query: {
                                        eid: response.payload.ehd_id,
                                        sid: response.payload.scene_id,
                                    },
                                })
                                this.loaderShow = false
                            }
                        })
                        this.loaderShow = false
                    } else {
                        that.toggleFullPageModal()
                        this.loaderShow = false
                    }
                })
            } else {
                this.toggleFullPageModal()
                this.loaderShow = false
            }
        },

        continueWithDraft() {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'auto'
            if (this.$route.query.cust === 'true' && this.$route.query.scene) {
                this.$router.push({
                    path: `/ehd/${this.draftEHDId}?scene=${this.draftSceneId}&cust=true`,
                })
            } else {
                this.$router.push({ path: `/ehd/${this.draftEHDId}` })
            }
            if (this.draftObject.operation === 'customise') {
                this.customiseScene()
            }

            this.draftModal = false
            this.draftModalMsg = ''
            this.showDraftVersions = false
        },
        restoreDraft() {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'auto'
            this.restoreDraftDetailsv3({
                ehd_id: this.ehdId,
                scene_id: this.currentSceneId,
            }).then(response => {
                if (response.responseCode === 200) {
                    if (
                        this.$route.query.cust == 'true' &&
                        this.$route.query.scene
                    ) {
                        this.$router.push({
                            path: `/ehd/${this.draftEHDId}?scene=${this.draftSceneId}&cust=true`,
                        })
                    } else {
                        this.$router.push({
                            path: `/ehd/${this.draftEHDId}`,
                        })
                    }

                    if (this.draftObject.operation == 'customise') {
                        this.customiseScene()
                    }
                }
            })

            this.draftModal = false
            this.draftModalMsg = ''
            this.showDraftVersions = false
        },
        activateSeeMore(bool) {
            this.seeMoreActivated = bool
            document.querySelector('.description').style.overflow = this
                .seeMoreActivated
                ? 'auto'
                : 'hidden'
        },

        addMoreProducts(obj) {
            this.add = true
            this.scene_id = obj.scene_id
            this.scene_type = obj.scene_type
            this.add = true
            this.$store.commit('setFiltersData', [])
            this.currentProductShow = false
            // this.fetchFilters({
            //     source: 'RelatedProduct',
            //     product_id: 210,
            // })
            // this.fetchProducts(0)
            this.fetchProducts(0)
                .then(() => {
                    this.loaderShow = false
                })
                .catch(() => {
                    this.loaderShow = false
                })
            this.toggleReplaceProductModal()
        },

        addProductForEHD(newproduct) {
            this.loaderShow = true
            this.toggleReplaceProductModal()
            customise_scene({
                scene_id: this.scene_id,
                product_id: newproduct.product_id,
                action: 'add_product',
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        if (
                            response.payload.changes_data.product
                                .active_quantity > 1
                        ) {
                            this.changeBaseProductCount(response)
                        } else {
                            this.addNewProduct(response)
                        }
                        this.currentTab = 0
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },
    },
}
</script>

<style lang="scss">
@import './EHDPage.scss';
</style>
